/* eslint-disable no-unused-vars */
import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { withRouter, NavLink, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Menu, Dropdown } from 'antd';
import classnames from 'classnames';
// import QS from 'query-string';

import Button from '../components/button';
import Typography from '../components/typography';
// import SearchInput from '../components/search-input-home';
import Clickable from '../components/clickable';
// import Thumbnail from '../components/thumbnail';
import { Images, Colors } from '../theme';
// import Misc from '../utils/misc';
// import Format from '../utils/format';
import Media from '../utils/media';
// import { logout } from '../utils/auth';
import LoginModal from './login-modal';

const HeaderContainer = styled.header`
  width: 100%;
  /* height: 64px; */
  height: 80px;
  padding: 10px 22px;
  z-index: 15;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  transition: all 0.2s;
  /* background-color: #0c2338; */
  background-color: #fff;
  box-shadow: none;
  border-bottom: 1px solid rgba(112, 112, 112, 0.15);

  &.header-blur-active {
    /* background-color: #0c2338; */
    background-color: #fff;
  }

  .left-box {
    display: flex;
    flex: 1;

    ${Media.lessThan(Media.SIZE.MD)} {
      justify-content: center;
    }

    .logo {
      margin-right: 56px;
      z-index: 2;
      align-items: center;
      display: flex;
      color: #fff;
      /* width: 178px; */
      height: 100%;

      img {
        height: 48px;
        width: 100%;
        object-fit: contain;
      }
    }

    .search-box {
      display: flex;
      justify-content: center;
      border-radius: 30px;
      padding-right: 30px;
      width: 100%;
      min-width: 350px;

      img {
        filter: brightness(0) invert(1);
      }

      > div {
        background-color: #3d4756;
        backdrop-filter: blur(18px) brightness(0.5);

        .input-wrapper {
          .input {
            padding-right: 0;
          }
        }
      }

      .search-type-box {
        background-color: #3d4756;

        .typography {
          color: #ffffff;
        }
      }

      ${Media.lessThan(Media.SIZE.MD)} {
        display: none;

        &.open {
          display: flex;
          position: fixed;
          top: 60px;
          padding: 10px;
          background: #262626;
          border-radius: 0;
          width: 100%;
          left: 0;
          z-index: 14;
          border-top: 1px solid rgba(154, 154, 154, 0.2);
        }
      }
    }
  }

  .guide-box {
    display: flex;
    align-items: center;
    margin-right: 24px;

    .typography {
      color: #282828;
      font-weight: bold !important;
    }

    .flag-icon {
      width: 17px;
      height: 17px;
      box-shadow: 0px 0px 3px 1px #00000021;
      background-position: center;
      background-size: cover;
      margin-right: 5px;
      border-radius: 10px;
      min-width: 0;
      margin-top: 2px;
    }

    .arrow-icon {
      width: 10px;
      min-width: 0;
      margin-left: 5px;
      /* filter: brightness(0) invert(1); */
    }
  }

  .right-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;

    .menu-button {
      margin-left: 20px;
      width: 20px;
      justify-content: center;
      display: flex;
      align-items: center;

      img {
        filter: brightness(0) invert(1);
      }
    }

    .login-button {
      padding: 0 35px;
      border: 1px solid #fff;
      background-color: transparent;
      color: #fff;
    }
    .back-button-guide {
      padding: 0 35px;
      /* border: 1px solid #3cc8fc; */
      border: 2px solid #045afc;
      background-color: transparent;
      /* color: #3cc8fc; */
      color: #045afc;
      border-radius: 60px;
    }

    .menu-list {
      display: flex;
      height: 100%;

      .menu-item {
        margin-right: 16px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 11px;
        color: #282828;

        &:last-child {
        }

        .typography {
          white-space: nowrap;
          color: #282828;
          /* font-family: 'Noto Sans JP'; */
          font-weight: bold;
        }

        &.active {
          color: #3cc8fc;

          p,
          .typography {
            color: #3cc8fc;
          }
        }
      }

      ${Media.lessThan(Media.SIZE.LG)} {
        display: none;
      }
    }

    .menu-external {
      border-left: 1px solid rgba(51, 51, 51, 0.15);
      padding-left: 15px;
    }

    .user-box {
      display: flex;
      height: 57px;
      // background-color: ${Colors.BLACK_1};
      padding-left: 7px;
      padding-right: 4px;
      align-items: center;
      border-radius: 6px 30px 30px 6px;

      ._left-box {
        min-width: 126px;

        ._horizotal {
          display: flex;
          align-items: flex-end;
          justify-content: center;

          .typography {
            // color: #333333;
          }

          > * {
            &:first-child {
              margin-right: 5px;
            }

            &:last-child {
              margin-bottom: 3px;
            }
          }
        }

        .typography {
          // color: #333333;
        }
      }

      ._avatar-box {
        margin-left: 12px;
        position: relative;

        ._dot {
          position: absolute;
          top: -1px;
          left: -1px;
          width: 15px;
          height: 15px;
          background-color: ${Colors.PRIMARY};
          border-radius: 8px;
          border: 3px solid #b9fdfd;
        }
      }
    }

    .item-box {
      display: flex;
      align-items: center;
      margin-right: 26px;
      margin-top: 5px;

      img {
        margin-right: 15px;
        width: 30px;
        height: 30px;
      }

      ._vertical {
        ._horizontal {
          display: flex;
          align-items: flex-end;
          margin-top: -5px;

          ._label {
            font-size: 13px;
            color: ${Colors.BLACK};
            margin-left: 5px;
            margin-bottom: 3px;
          }

          ._number {
            color: ${Colors.BLACK};
          }
        }
      }
    }
  }

  .menu-panel {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    display: flex;
    flex-direction: column;

    .top-menu-box {
      // background-color: ${Colors.BLACK_2};
      .menu-item {
        display: flex;
        justify-content: space-between;
        border-bottom: solid 1px rgba(255, 255, 255, 0.15);
        height: 56px;
        align-items: center;
        padding: 0 20px;

        .typography {
          color: #ffffff;
        }

        > img {
          width: 8px;
        }

        .guide-box {
          margin-right: 0;

          .arrow-icon {
            width: 13px;
            margin-left: 10px;
            filter: brightness(0) invert(1);
          }

          .typography {
            /* color: #ffffff; */
            color: #282828;
          }
        }

        &.language-item {
          display: none;
        }
      }
    }

    .bottom-menu-box {
      padding: 20px;
      // background-color: ${Colors.BLACK_2};

      .bottom-menu-item {
        margin-bottom: 8px;
        display: block;
        font-size: 14px;
        color: #ffffff;
        /* font-family: 'Noto Sans JP'; */

        &:hover {
          opacity: 0.9;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &.open {
    background-color: rgba(0, 0, 0, 0.8);
  }

  &.is-light {
    &.open {
      background-color: #ffffff;
    }

    .menu-panel .top-menu-box .menu-item .guide-box .arrow-icon {
      -webkit-filter: unset;
      filter: unset;
    }
  }

  ${Media.lessThan(Media.SIZE.MD)} {
    height: 60px;

    .left-box {
      .menu-button {
        margin-left: 3px;
        margin-right: 15px;

        img {
          width: 17px;
        }
      }

      .logo {
        margin-right: 5px;
        // border-left: 1px solid rgba(255, 255, 255, 0.15);
        padding-left: 10px;
        height: 38px;
        display: flex;

        img {
          max-width: 150px;
          // height: 25px;
          z-index: 99;
        }
      }
    }

    .right-box {
      .menu-list {
        display: none;
      }

      .guide-box {
        display: none;
      }

      .login-button {
        padding: 0;
        width: 140px;
      }

      .user-box {
        height: 38px;
        padding-left: 5px;
        padding-right: 2px;

        ._left-box {
          min-width: 100px;

          ._horizotal {
            display: flex;
            align-items: flex-end;
            justify-content: center;

            .typography {
              color: #333333;
            }

            > * {
              &:first-child {
                margin-right: 5px;
                font-size: 13px;
              }

              &:last-child {
                margin-bottom: 3px;
                font-size: 10px;
              }
            }
          }

          .header-public-address {
            font-size: 10px;
          }
        }

        ._avatar-box {
          margin-left: 5px;

          ._dot {
            top: 0;
            left: 0;
            width: 10px;
            height: 10px;
            border: 2px solid #b9fdfd;
          }

          .avatar-image {
            width: 36px;
            height: 36px;
          }
        }
      }
    }

    .menu-panel {
      top: 60px;

      .top-menu-box {
        .menu-item {
          &.language-item {
            display: flex;
          }
        }
      }
    }
  }

  ${Media.lessThan(Media.SIZE.XXXS)} {
    height: 45px;

    .left-box {
      .logo {
        img {
          max-width: 100px;
          height: 50px;
        }
      }
    }
  }

  &.is-light {
    background-color: #0c2338;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);

    &.header-blur-active {
      background-color: #0c2338;
    }

    .menu-panel {
      background-color: #ffffff;
    }

    .menu-panel .top-menu-box .menu-item .typography,
    .menu-panel .bottom-menu-box .bottom-menu-item {
      color: #333333;
    }

    .menu-panel .top-menu-box .menu-item {
      border-bottom: solid 1px rgba(0, 0, 0, 0.15);
    }

    .search-box {
      img {
        filter: unset;
      }

      > div {
        background-color: #eeeeee;
        backdrop-filter: unset;
      }

      .search-type-box {
        background-color: rgba(39, 109, 214, 0.08);

        .typography {
          color: #2664c1;
        }
      }

      input::placeholder {
        color: rgba(51, 51, 51, 0.5);
      }

      &.open {
        ${Media.lessThan(Media.SIZE.MD)} {
          background-color: #ffffff;
        }
      }
    }

    .right-box .menu-list .menu-item .typography,
    .guide-box .typography {
      color: #fff !important;
      /* font-family: 'Noto Sans JP' !important; */
    }

    .right-box .menu-list .menu-item.active .typography {
      color: #2664c1;
    }

    .guide-box .arrow-icon {
      filter: unset;
    }

    .right-box .menu-external {
      border-left: 1px solid rgba(51, 51, 51, 0.15);
    }

    .right-box .login-button {
      // background-color: #ffffff;
    }

    .right-box .menu-button img {
      filter: unset;
      opacity: 1;
    }
  }
`;
const StyledPopoverContent = styled.div`
  display: flex;
  flex-direction: column;

  a {
    margin-bottom: 10px;
    user-select: none;
    color: inherit;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const FlagItemBox = styled(Clickable)`
  display: flex;
  align-items: center;

  .typography {
    margin-right: 25px;
  }

  img {
    margin-top: 2px;
    width: 20px;
    margin-right: 5px;
    box-shadow: 0px 0px 3px 1px #00000021;
  }
`;

const MenuFixedBottom = styled.div`
  display: none;

  ${Media.lessThan(Media.SIZE.MD)} {
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 15;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: #262626;
    box-shadow: none;

    .header-fixed-bottom-inner {
      display: flex;
      width: 100%;

      ._item {
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 6px 10px;
        cursor: pointer;

        ._icon {
          margin-bottom: 4px;

          img {
            width: 30px;
            height: 30px;
            object-fit: contain;
          }

          .hover {
            display: none;
          }
        }

        ._text {
          font-size: 9px;
          color: #9a9a9a;
        }

        &:hover,
        &.active {
          ._icon {
            .hover {
              display: block;
            }

            .normal {
              display: none;
            }
          }

          ._text {
            color: #3cc8fc;
          }
        }
      }
    }
  }

  &.is-dark {
    background-color: #262626;
  }

  &.is-light {
    background: #262626;
    box-shadow: 0 0 6px rgba(34, 34, 34, 0.2);
  }
`;

@withTranslation('common')
@withRouter
@inject(stores => ({
  authStore: stores.auth,
  categoriesStore: stores.categories,
}))
@observer
class HeaderGuide extends Component {
  static propTypes = {
    authStore: PropTypes.object,
    location: PropTypes.object,
    categoriesStore: PropTypes.object,
  };

  state = {
    isOpenMenuPanel: false,
    isOpenSearchMobile: false,
    classFixedHeader: '',
  };

  _onOpenMyPage = e => {
    const { history } = this.props;
    e.preventDefault();

    history.push('/my-page');
    this.setState({
      isOpenMenuPanel: false,
      isOpenSearchMobile: false,
    });
  };

  _onLoginButtonClick = () => {
    LoginModal.open();
  };

  _onBackHome = () => {
    const { history } = this.props;
    history.push('/');
    this.setState({
      isOpenMenuPanel: false,
      isOpenSearchMobile: false,
      defaultCategoryIdSelect: '',
      defaultSearchValue: '',
    });
  };

  _onToggleMenuPanel = () => {
    this.setState(state => ({
      isOpenMenuPanel: !state.isOpenMenuPanel,
      isOpenSearchMobile: false,
    }));
  };

  _onToggleSearchMobile = () => {
    this.setState(state => ({
      isOpenMenuPanel: false,
      isOpenSearchMobile: !state.isOpenSearchMobile,
    }));
  };

  onClickTransPage = type => {
    const { history } = this.props;
    history.replace(`/${type}`);
  };

  _onMenuPanelClick = item => {
    const { history } = this.props;

    history.push(item.to);

    this.setState({
      isOpenMenuPanel: false,
      isOpenSearchMobile: false,
    });
  };

  _onSearch = () => {
    this.setState({
      isOpenMenuPanel: false,
      isOpenSearchMobile: false,
    });
  };

  _renderGuideBox = () => {
    const MENU_DROPDOWN_GUIDE = [
      {
        name: 'NFTをはじめる',
        to: 'guide',
      },
      {
        name: 'アイテムを買う',
        to: 'buy-nft',
      },
    ];
    return (
      <Dropdown
        trigger="click"
        overlay={
          <Menu>
            {MENU_DROPDOWN_GUIDE.map((item, index) => (
              <Menu.Item key={index}>
                <FlagItemBox effect={false} onClick={() => this.onClickTransPage(item.to)}>
                  <Typography bold>{item.name}</Typography>
                </FlagItemBox>
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <Clickable className="guide-box">
          <Typography bold>ご利用ガイド</Typography>
          <img className="arrow-icon" src={Images.GRAY_CHEVRON_DOWN_ICON} alt="icon" />
        </Clickable>
      </Dropdown>
    );
  };

  _renderMenuPanel = MENU_ITEMS => {
    const { t } = this.props;
    const { isOpenMenuPanel } = this.state;

    if (!isOpenMenuPanel) return null;

    const BOTTOM_MENU_ITEMS = [
      {
        name: t('footer.about'),
        path: '/about',
      },
      {
        name: t('footer.faq'),
        path: '/faqs',
      },
      {
        name: t('footer.service_term'),
        path: '/service-terms',
      },
      {
        name: t('footer.privacy'),
        path: '/privacy-agreement',
      },
    ];

    return (
      <div className="menu-panel">
        <div className="top-menu-box">
          {MENU_ITEMS.map((item, index) => (
            <Clickable key={index} className="menu-item" onClick={() => this._onMenuPanelClick(item)}>
              <Typography>{item.name}</Typography>
              <img src={Images.WHITE_CHEVRON_RIGHT_ICON} alt="" />
            </Clickable>
          ))}
          <div className="menu-item language-item">
            <Typography>Guide</Typography>
            {this._renderGuideBox()}
          </div>
        </div>
        <div className="bottom-menu-box">
          {BOTTOM_MENU_ITEMS.map((item, index) => (
            <Link to={item.path} key={index} className="bottom-menu-item">
              {item.name}
            </Link>
          ))}
        </div>
      </div>
    );
  };

  componentDidMount() {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        this.setState({ classFixedHeader: 'header-blur-active' });
      } else {
        this.setState({ classFixedHeader: '' });
      }
    });
  }

  render() {
    const { authStore, t, i18n, location } = this.props;
    const { isOpenMenuPanel, isOpenSearchMobile, classFixedHeader } = this.state;
    const currentPath = location?.pathname;
    const isHome = `/${i18n.language}/` === currentPath || `/${i18n.language}` === currentPath;
    let pathName = currentPath.slice(4, currentPath.length);

    const MENU_ITEMS_GUIDE = [
      {
        name: 'よくあるご質問',
        to: '/faqs',
      },
    ];

    const MENU_ITEMS = [
      {
        name: t('header.nft_items'),
        to: '/nft-items',
      },
      //  {
      //   name: t('header.creators'),
      //   to: '/'
      // }
    ];

    const isLogin = authStore.loggedIn && authStore?.initialData?.publicAddress;

    return (
      <div className="header">
        <HeaderContainer className={classnames({ open: isOpenMenuPanel }, classFixedHeader)}>
          <div className="left-box">
            <img src={Images.LOGO} alt="logo" />
            {/* <Clickable className="logo" onClick={this._onBackHome}>
              <img src={Images.LOGO} alt="logo" />
            </Clickable> */}
          </div>
          <div className="right-box pc">
            {this._renderGuideBox()}
            <div className="menu-list">
              {MENU_ITEMS_GUIDE.map((item, index) => (
                <NavLink exact key={index} to={`/${i18n.language}${item.to}`} className="menu-item">
                  <Typography>{item.name}</Typography>
                </NavLink>
              ))}
            </div>
            <Button className="back-button-guide" onClick={this._onBackHome}>
              マーケットはこちら
            </Button>
          </div>

          {this._renderMenuPanel(MENU_ITEMS_GUIDE)}
        </HeaderContainer>
        <MenuFixedBottom>
          <div className="header-fixed-bottom-inner">
            <Clickable className={classnames({ active: isHome }, '_item')} onClick={this._onBackHome}>
              <div className="_icon">
                <img className="normal" src={Images.MENU_HOME_ICON} alt="icon" />
                <img className="hover" src={Images.MENU_HOME_ACTIVE_ICON} alt="icon" />
              </div>
              <div className="_text">{t('header.home')}</div>
            </Clickable>
            <Clickable className="_item" onClick={this._onToggleSearchMobile}>
              <div className="_icon">
                <img className="normal" src={Images.MENU_SEARCH_ICON} alt="icon" />
                <img className="hover" src={Images.MENU_SEARCH_ACTIVE_ICON} alt="icon" />
              </div>
              <div className="_text">{t('header.search')}</div>
            </Clickable>
            {isLogin ? (
              <Clickable className="_item" onClick={this._onOpenMyPage}>
                <div className="_icon">
                  <img className="normal" src={Images.MENU_WALLET_ICON} alt="icon" />
                  <img className="hover" src={Images.MENU_WALLET_ACTIVE_ICON} alt="icon" />
                </div>
                <div className="_text">{t('header.wallet')}</div>
              </Clickable>
            ) : (
              <Clickable className="_item" onClick={this._onLoginButtonClick}>
                <div className="_icon">
                  <img className="normal" src={Images.MENU_WALLET_ICON} alt="icon" />
                  <img className="hover" src={Images.MENU_WALLET_ACTIVE_ICON} alt="icon" />
                </div>
                <div className="_text">{t('header.wallet')}</div>
              </Clickable>
            )}
            <Clickable className="_item" onClick={this._onToggleMenuPanel}>
              <div className="_icon">
                <img className="normal" src={Images.MENU_MORE_ICON} alt="icon" />
                <img className="hover" src={Images.MENU_MORE_ACTIVE_ICON} alt="icon" />
              </div>
              <div className="_text">{t('header.more')}</div>
            </Clickable>
          </div>
        </MenuFixedBottom>
      </div>
    );
  }
}

export default HeaderGuide;
