import firebase from 'firebase/app';
import 'firebase/firestore';

// import Configs from "../configs"

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// const env = Configs.ENV === "local" ? "development" : Configs.ENV
const env = process.env.REACT_APP_FIREBASE_ENV === 'local' ? 'develop' : process.env.REACT_APP_FIREBASE_ENV;

if (!firebase.apps.length) {
  // firebase.initializeApp(Configs.FIREBASE);
  firebase.initializeApp(config);
}

const instance = firebase.firestore();
const firestore = {
  instance,
  orders: () => instance.collection(`${env}`).doc('orders').collection('items'),
  bids: () => instance.collection(`${env}`).doc('bids').collection('items'),
  bidHistories: () => instance.collection(`${env}`).doc('bidHistories').collection('items'),
  bannersLangs: () => instance.collection(`${env}`).doc('bannersLangs').collection('items'),
  adsLangs: () => instance.collection(`${env}`).doc('adsLangs').collection('items'),
  logs: () => instance.collection(`${env}`).doc('logs').collection('items'),
  documentId: () => firebase.firestore.FieldPath.documentId(),
  gacha: () => instance.collection(`${env}`).doc('gacha').collection('items'),
  wallet: () => instance.collection(`${env}`).doc('wallet').collection('items'),
};

export { firestore };
