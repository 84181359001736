import React from 'react';
import { inject, observer } from 'mobx-react';

import Typography from '../components/typography';
import Format from '../utils/format';

function BalanceBox({ balance }) {
  return (
    <>
      <Typography size="huge" bold>
        {Format.handleDecimalPlace(balance)}
      </Typography>
      <Typography bold>MCC Point</Typography>
    </>
  );
}

// export default BalanceBox;
export default inject(stores => ({
  authStore: stores.auth,
}))(
  observer(({ authStore, ...props }) => {
    return (
      <BalanceBox
        {...props}
        balance={authStore?.initialData?.balance}
      />
    );
  }),
);
