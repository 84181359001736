import { types } from 'mobx-state-tree';

import { Model } from '../utils/mobx-model-helper';
import {
  getListLending,
  getLendingById,
  borrowLendingProduct,
  borrowLendingOwned,
  cancelLendingOwned,
  lendingOwned,
  registerLendingProduct,
  transactionLendingProduct,
  withdrawLendingOwned,
  lendingPriceAvg,
} from '../api/lending';

const TYPES = {
  GET_LIST_LENDING: 1,
  GET_LENDING_BY_ID: 2,
  BORROW_LENDING_PRODUCT: 3,
  BORROW_LENDING_OWNED: 4,
  CANCEL_LENDING_OWNED: 5,
  LENDING_OWNED: 6,
  REGISTER_LENDING_PRODUCT: 7,
  TRANSACTION_LENDING_PRODUCT: 8,
  WITHDRAW_LENDING_OWNED: 9,
  LENDING_PRICE_AVG: 10,
};

const Lending = types.model('Lending').props({
  id: types.identifierNumber,
  contractAddress: types.maybeNull(types.string),
  licenseId: types.maybeNull(types.number),
  licenseAddress: types.maybeNull(types.string),
  licenseFee: types.maybeNull(types.number),
  imageUrl: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  price: types.maybeNull(types.number),
  currentTime: types.maybeNull(types.number),
  yenPrice: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  userId: types.maybeNull(types.number),
  size: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  currency: types.maybeNull(types.string),
  holding: types.maybeNull(types.boolean),
  rareFlg: types.maybeNull(types.boolean),
  newAuctionFlag: types.maybeNull(types.boolean),
  productBid: types.maybeNull(
    types.model({
      status: types.maybeNull(types.string),
      startTime: types.maybeNull(types.number),
      endTime: types.maybeNull(types.number),
      biggestBidPrice: types.maybeNull(types.number),
      biggestBidPriceYen: types.maybeNull(types.number),
    }),
  ),
  countView: types.maybeNull(types.number),
  productAttributes: types.maybeNull(
    types.array(
      types.model({
        id: types.maybeNull(types.number),
        name: types.maybeNull(types.string),
        value: types.maybeNull(types.string),
        type: types.maybeNull(types.string),
        attribute: types.maybeNull(types.number),
      }),
    ),
  ),
  series: types.maybeNull(types.string),
  tokenType: types.maybeNull(types.string),
  reselling: types.maybeNull(types.boolean),
  productOwnerRole: types.maybeNull(types.string),
  tokenId: types.maybeNull(types.number),
  offerNftTransactionStatus: types.maybeNull(types.string),
  canOfferFlag: types.maybeNull(types.boolean),
  offerHighestPrice: types.maybeNull(types.number),
  offerHighestPriceCurrency: types.maybeNull(types.string),
  volume: types.maybeNull(types.number),
  categoryName: types.maybeNull(types.string),
  wishCount: types.maybeNull(types.number),
  canResellERC1155: types.maybeNull(types.boolean),
  creatorName: types.maybeNull(types.string),
  isUserWished: types.maybeNull(types.boolean),
  tokenStandard: types.maybeNull(types.string),
  chainId: types.maybeNull(types.number),
  lendingStatus: types.maybeNull(types.string),
  ownerAddress: types.maybeNull(types.string),
  productBuying: types.maybeNull(types.boolean),
  isApproveOffer: types.maybeNull(types.boolean),
  ownerAvatar: types.maybeNull(types.string),
  ownerName: types.maybeNull(types.string),
  pricePerDay: types.maybeNull(types.string),
  rentalDuration: types.maybeNull(types.number),
  rentalDurationEndTime: types.maybeNull(types.number),
  rentalDurationStartTime: types.maybeNull(types.number),
  offerExpirationEndTime: types.maybeNull(types.number),
  offerExpirationStartTime: types.maybeNull(types.number),
  itemColor: types.maybeNull(types.string),
  imageThumbnailUrls: types.array(types.maybeNull(types.string)),
  username: types.maybeNull(types.string),
  lendingTitle: types.maybeNull(types.string),
  offerExpiration: types.maybeNull(types.number),
});

const Lendings = types.model('Lendings').props({
  items: types.array(Lending),
  page: types.maybeNull(types.number),
  total: types.maybeNull(types.number),
});

const LendingStore = Model.named('LendingStore')
  .props({
    lendings: Lendings,
    listLendingOwned: Lendings,
    listBorrowLendingOwned: Lendings,
    lending: types.maybeNull(Lending),
  })
  .actions(self => ({
    getListLending(payload, { concat }) {
      return self.request({
        type: TYPES.GET_LIST_LENDING,
        api: getListLending,
        payload,
        onSuccess: result => {
          const items = result?.result;
          self.lendings = {
            items: concat ? self.lendings.items.concat(items) : items,
            page: result.page,
            total: result.total,
          };
        },
      });
    },
    getLendingById(payload) {
      return self.request({
        type: TYPES.GET_LENDING_BY_ID,
        api: getLendingById,
        payload,
        onSuccess: result => {
          self.lending = result
        },
      });
    },
    borrowLendingProduct(payload) {
      return self.request({
        type: TYPES.BORROW_LENDING_PRODUCT,
        api: borrowLendingProduct,
        payload,
        onSuccess: result => {
          // console.log('result', result)
        },
      });
    },
    borrowLendingOwned(payload, { concat }) {
      return self.request({
        type: TYPES.BORROW_LENDING_OWNED,
        api: borrowLendingOwned,
        payload,
        onSuccess: result => {
          const items = result?.result;
          self.listBorrowLendingOwned = {
            items: concat ? self.listBorrowLendingOwned.items.concat(items) : items,
            page: result.page,
            total: result.total,
          };
        },
      });
    },
    cancelLendingOwned(payload) {
      return self.request({
        type: TYPES.CANCEL_LENDING_OWNED,
        api: cancelLendingOwned,
        payload,
      });
    },
    withdrawLendingOwned(payload) {
      return self.request({
        type: TYPES.WITHDRAW_LENDING_OWNED,
        api: withdrawLendingOwned,
        payload,
      });
    },
    lendingOwned(payload, { concat }) {
      return self.request({
        type: TYPES.LENDING_OWNED,
        api: lendingOwned,
        payload,
        onSuccess: result => {
          const items = result?.result;
          self.listLendingOwned = {
            items: concat ? self.listLendingOwned.items.concat(items) : items,
            page: result.page,
            total: result.total,
          };
        },
      });
    },
    registerLendingProduct(payload) {
      return self.request({
        type: TYPES.REGISTER_LENDING_PRODUCT,
        api: registerLendingProduct,
        payload,
        onSuccess: result => {
          // console.log('result', result)
        },
      });
    },
    transactionLendingProduct(payload) {
      return self.request({
        type: TYPES.TRANSACTION_LENDING_PRODUCT,
        api: transactionLendingProduct,
        payload,
        onSuccess: result => {
          // console.log('result', result)
        },
      });
    },
    lendingPriceAvg(payload) {
      return self.request({
        type: TYPES.LENDING_PRICE_AVG,
        api: lendingPriceAvg,
        payload,
      });
    },
  }));

export { TYPES };
export default LendingStore.create({
  lendings: {
    items: [],
    page: 1,
    total: 0,
    pages: 1,
  },
  listBorrowLendingOwned: {
    items: [],
    page: 1,
    total: 0,
    pages: 1,
  },
  listLendingOwned: {
    items: [],
    page: 1,
    total: 0,
    pages: 1,
  },
  lending: null
});
