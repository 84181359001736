import { Form } from 'antd';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import { useContext, useEffect, useRef } from 'react';
import { MobXProviderContext } from 'mobx-react';
import moment from 'moment';

import Storage from '../utils/storage';
import Request from '../utils/request';

import Toast from './toast';
import Button from './button';
import MaskLoading from './mask-loading';
import { OTPInputComponent } from './otp-input';
import useModalInvalidEmail from '../hooks/useModalInvalidEmail';
import { useTranslation } from 'react-i18next';
import { useWeb3Auth } from '../contexts/web3auth/web3auth';
import { channel } from '../utils/auth';

const ModalVerifyCodeStyled = styled.div`
  .title-header {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    min-height: 50px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .sub-title {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding-top: 4px;
    color: #fff;
  }

  input {
    background-color: #fff;
    border-color: black;
    border-width: 1px;
    color: black;

    &::placeholder {
      color: #999999;
    }
  }
  .error {
    margin-top: 2px;
    color: red;
    text-align: center;
  }

  .action-box {
    margin: 20px 0px;
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 0px 24px;

    .btn-submit {
      padding: 0px 10px;
      border-radius: 10px;
      height: 50px;
      width: 50%;
      background: #1877f2;
      border-radius: 9999px;

      &.ant-btn:disabled {
        background: #fff;
      }
    }
  }

  .content-verify {
    text-align: center;
    color: #fff;
    margin-bottom: 10px;
  }

  .box-generate-code {
    @media (max-width: 575px) {
      margin-bottom: 20px;
    }
  }
`;

export const ModalVerifyCode = ({
  setDataVerifyCode = () => { },
  setIsShowVerifyCode = () => { },
  dataVerifyCode,
  isShowVerifyCode,
  controlVerifyCode,
  errorVerifyCode,
  isValidVerifyCode,
  handleSubmitVerifyCode,
  triggerVerifyCode,
  getValueVerifyCode,
  resetVerifyCode,
  setCounter,
  counter,
}) => {
  const { t } = useTranslation('auth');
  const { auth } = useContext(MobXProviderContext);
  const { open: openModalInvalidEmail } = useModalInvalidEmail();
  const { setExpiredToken, setRefreshToken } = useWeb3Auth();

  const onSubmit = data => {
    triggerVerifyCode();
    if (isValidVerifyCode) {
      requestVerifyCode(data);
    }
  };

  const timerIdRef = useRef(null);

  const startCountdown = (time) => {
    if (timerIdRef.current) {
      clearInterval(timerIdRef.current);
    }

    if (time) setCounter(time);
    const tick = () => {
      setCounter(prevCounter => {
        if (prevCounter <= 0) {
          clearInterval(timerIdRef.current);
          return 0;
        }
        return prevCounter - 1;
      });
    };
    timerIdRef.current = setInterval(tick, 1000);
  };

  useEffect(() => {
    startCountdown();
    return () => clearInterval(timerIdRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    if (isShowVerifyCode && counter === 0) {
      generateCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowVerifyCode]);
  const generateCode = () => {
    if (counter) return;
    getLoginCode();
  };

  const getLoginCode = async () => {
    try {
      const { data: dataRes } = await auth.verifyEmailLogin({ email: dataVerifyCode });
      receiveResponseLoginCode(dataRes);
    } catch (error) {
      Toast.error(error?.error);
    }
  };

  const receiveResponseLoginCode = result => {
    const time = result?.expiredAt - (moment.utc().unix()) - 1;
    setCounter(time > 60 ? 60 : time);
    startCountdown(time > 60 ? 60 : time);
  };

  const handleSubmitCrossSite = async ({ accounts, identifier }) => {
    const { data, success } = await auth.loginSSOWalletSignIn({
      auth_token: accounts,
      identifier: identifier,
    });
    if (success) {
      Storage.set('ACCESS_TOKEN_WALLET', data.access_token);
      Storage.set('PUBLIC_ADDRESS_WALLET', data.public_address);
      Storage.set('USER_ID_WALLET', data.user_id);
      Storage.set('EXPIRED_TOKEN_WALLET', data.expired_at);
      await auth.getBalanceWallet(data.access_token);
    }
  };

  const handleCrossSite = async isLogin => {
    if (isLogin) {
      const { success, data } = await auth.retrieveAccount();
      if (success) {
        handleSubmitCrossSite({
          accounts: data.accounts[0].auth_token,
          identifier: data.accounts[0].identifier,
        });
      }
    }
  };

  const requestVerifyCode = async value => {
    MaskLoading.open({});
    const { success, data: dataRes } = await auth.authLogin({ codeLogin: value.code.toUpperCase(), email: dataVerifyCode });
    if (success) {
      setExpiredToken(dataRes.expiredAt);
      setRefreshToken(dataRes.refreshToken);
      Storage.set('ACCESS_TOKEN', dataRes.accessToken);
      Storage.set('USER_ID', dataRes.userId);
      Storage.set('PUBLIC_ADDRESS', dataRes.publicAddress);
      Storage.set('EXPIRED_TOKEN', dataRes.expiredAt);
      Storage.set('REFRESH_TOKEN', dataRes.refreshToken);
      Request.setAccessToken(dataRes.accessToken);
      await auth.setLoggedIn(true);
      auth.setInitialData({
        publicAddress: dataRes.publicAddress,
        userId: dataRes.userId,
        email: dataRes.email,
        externalWallet: dataRes.externalWallet,
      });
      if (dataRes?.inactive) {
        openModalInvalidEmail();
      } else {
        const response = await auth.getInitialData({ headers: dataRes.accessToken });
        const {data} = response;
        const sessionData = data?.web3SessionData;
        if (sessionData !== null) {
          const parseSession = JSON.parse(sessionData);
          const idSession = parseSession.idSession;
          Storage.set('openlogin_store', idSession);
          const cachedAdapter = parseSession.cachedAdapter;
          localStorage.setItem('Web3Auth-cachedAdapter', cachedAdapter);
          Storage.set('SSO_ADAPTER', idSession);
        }
      }
      await auth.getInitialData({ headers: dataRes.accessToken });
      setDataVerifyCode(null);
      setIsShowVerifyCode(false);
      resetVerifyCode();
      channel.postMessage("LOGIN");
      await handleCrossSite(success);
    }

    MaskLoading.close();
  };

  return (
    <ModalVerifyCodeStyled>
      <Form onSubmit={handleSubmitVerifyCode(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmitVerifyCode(onSubmit)();
          }
        }}
      >
        <div className="header">
          <div className="title-header">{t('login.login')}</div>
          <div className="sub-title">{t('login.sub_title_verify_code')}</div>
          <div className="box-generate-code">
            <Controller
              name="code"
              control={controlVerifyCode}
              render={({ field: { onChange, value } }) => (
                <OTPInputComponent otp={value || ''} setOtp={onChange}></OTPInputComponent>
              )}
            />
            {errorVerifyCode?.code && <p className="error">{errorVerifyCode.code.message}</p>}
          </div>
        </div>
        <div className="action-box">
          <Button onClick={() => generateCode()} className="btn-submit" type="blue">
            <div className={`generate ${counter && 'disabled'}`}>
              {t('login.generate')} &nbsp;<span>{counter ? `(${counter}s)` : ''}</span>
            </div>
          </Button>
          <Button onClick={() => onSubmit(getValueVerifyCode())} className="btn-submit">
            {t('login.submit_btn')}
          </Button>
        </div>
        <div className="content-verify">{t('login.messages.note_message_verify_code', { email: dataVerifyCode })}</div>
      </Form>
    </ModalVerifyCodeStyled>
  );
};
