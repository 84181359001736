/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { MobXProviderContext } from 'mobx-react';
import { WALLET_ADAPTERS } from '@web3auth/base';
import { Form, Input, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Modal from '../components/modal';
import Typography from '../components/typography';
import Clickable from '../components/clickable';
import { Colors, Images } from '../theme';
import { getWeb3Instance } from '../utils/web3';
import Toast from '../components/toast';
import Storage from '../utils/storage';
import Request from '../utils/request';
import Misc from '../utils/misc';
import Media from '../utils/media';
import Button from '../components/button';
import { useWeb3Auth } from '../contexts/web3auth/web3auth';
import { useAuth } from '../hooks/auth';
import useModalInvalidEmail from '../hooks/useModalInvalidEmail';
import { logout } from '../utils/auth';
import Confirmable from '../components/confirmable';
import MaskLoading from '../components/mask-loading';

const HeaderStyled = styled.div`
  width: 100%;
  min-height: 50px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  .title {
    font-size: 18px;
    color: #040c14;
    text-transform: uppercase;
    color: #d0d0d0 !important;
  }
`;
const ContentStyled = styled.div`
  padding: 0px 40px 40px 40px;

  .subtitle {
    margin-bottom: 5px;
    color: ${Colors.TEXT};
    font-size: 14px;
    font-weight: 400;
  }

  .title-2 {
    margin-bottom: 17px;
    color: #fff;
  }

  .login-button {
    height: 64px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    padding: 0 25px;

    /* background-color: #15171c !important; */
    border-radius: 6px;
    align-items: center;
    color: #fff;

    img {
      width: 34px;
      margin-right: 10px;
    }

    &.metamask {
      background: #15171c;
      margin-bottom: 12px;
    }

    &.walletconnect {
      background: rgb(245, 132, 31);
      background: linear-gradient(90deg, rgb(106, 163, 208) 0%, rgb(208, 227, 239) 100%);
      margin-bottom: 12px;
    }

    p {
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 5px;
      font-size: 18px;
      font-weight: 500;

      ${Media.lessThan(Media.SIZE.XS)} {
        font-size: 15px;
      }

      span {
        letter-spacing: 2px;
        font-size: 14px;
        text-transform: capitalize;
      }
    }
  }

  .text-problem {
    color: #9aa8bd;
    font-size: 14px;
    margin-bottom: 30px;
  }

  .faq-link {
    margin-top: 7px;
  }

  .wrap-button {
    display: flex;
    /* justify-content: space-between; */
    gap: 15px;

    .button-social {
      flex: 1;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 130px;
      height: 60px;
      background: #1877f2;
      border-radius: 6px;
    }

    .google {
      background: #0099ff;
    }

    .twitter {
      background: #03a9f4;
    }
    .discord {
      background: #5865f2;
    }
  }

  .ant-divider {
    color: ${Colors.TEXT};
    font-size: 16px;
    text-transform: uppercase;
    &::before,
    &::after {
      background-color: ${Colors.TEXT};
      color: ${Colors.TEXT};
    }
  }
`;

export const FormEmailStyled = styled.div`
  text-align: center;
  .ant-form-item {
    margin-bottom: 14px;
    height: 48px;
  }

  .ant-form-item-explain.ant-form-item-explain-connected {
    margin-bottom: 20px;
  }

  .input-email {
    height: 48px;
    border-radius: 28px;
    border: 1px solid rgba(101, 103, 107, 0.5);
    background: #1b1e24;

    &.ant-input-affix-wrapper-status-error.ant-input-affix-wrapper {
      background: transparent !important;

      &:hover {
        background: transparent !important;
      }
    }

    .ant-input {
      color: #ffffff;
      background: transparent;
      caret-color: #ffffff;

      &::placeholder {
        color: ${Colors.TEXT};
        font-size: 16px;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    input:-webkit-autofill:focus-within,
    input:-webkit-autofill:focus-visible,
    input:-webkit-autofill:target {
      -webkit-background-clip: text;
      -webkit-text-fill-color: #ffffff !important;
    }

    .ant-input-suffix {
      background: transparent;
    }
  }

  .ant-form-item-has-error {
    .input-email {
      border: 1px solid red;
      background: transparent;

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active,
      input:-webkit-autofill:focus-within,
      input:-webkit-autofill:focus-visible,
      input:-webkit-autofill:target {
        -webkit-background-clip: text;
        -webkit-text-fill-color: #ffffff !important;
      }

      &:hover {
        background: transparent;
      }
    }
  }

  .btn-login-email {
    margin-top: 10px;
    height: 46px;
    background: #1877f2;
    border-radius: 9999px;
    width: 100%;
  }
`;

const LoginModal = ({ onClose, isOpen }) => {
  const { t } = useTranslation('auth');
  const { auth } = useContext(MobXProviderContext);

  const { onLoginAction } = useAuth();
  const {
    login: loginByWeb3auth,
    logout: logoutByWeb3auth,
    web3Auth,
    getAccounts,
    getUserInfo,
    getBalance: getBalanceWeb3auth,
    getAppPubKey,
  } = useWeb3Auth();
  const { open: openModalInvalidEmail } = useModalInvalidEmail();
  const [loading, setLoading] = useState(false);

  const handleCloseModal = () => {
    formik.resetForm({});
    if (onClose) onClose();
  };

  const handleLogin = async type => {
    if (loading) return null;
    // setLoading(true);
    MaskLoading.open({ message: t('login.messages.proccessing_on_metamask') });

    try {
      const { web3 } = await getWeb3Instance();

      if (!web3) return null;

      let publicAddress;
      const [account] = await web3.currentProvider.enable();
      publicAddress = account;

      await onLoginAction({ web3, web3Provider: web3.currentProvider, publicAddress });
      handleCloseModal();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      logout();
      MaskLoading.close();

      Toast.error(t('login.messages.cannot_login'));
    } finally {
      // setLoading(false);
      MaskLoading.close();
    }
  };

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email(t('validation_messages:EMAIL_INVALID'))
        .max(30, t('validation_messages:MAX_LENGTH_30_EMAIL'))
        .required(t('validation_messages:EMAIL_REQUIRED')),
    }),
    enableReinitialize: true,
    onSubmit: ({ email }) => {
      handleLoginWeb3Auth(WALLET_ADAPTERS.OPENLOGIN, 'email_passwordless', email);
    },
  });
  const { touched, handleSubmit, values, isValid, errors, setFieldValue, setFieldTouched } = formik;

  const handleLoginWeb3Auth = async (adapter, social = null, valueSocial = null) => {
    if (loading) return null;
    setLoading(true);

    try {
      const responseWeb3Auth = await loginByWeb3auth(adapter, social, valueSocial);
      const idSession = Storage.get('openlogin_store');
      const cachedAdapter = localStorage.getItem('Web3Auth-cachedAdapter');
      const dataObj = { idSession, cachedAdapter };
      const web3SessionData = JSON.stringify(dataObj);
      if (!responseWeb3Auth) {
        return;
      }

      if (adapter === WALLET_ADAPTERS.OPENLOGIN) {
        const userInfo = await getUserInfo();
        const accounts = await getAccounts();
        const appPubKey = await getAppPubKey();
        const balance = await getBalanceWeb3auth();
        const publicAddress = responseWeb3Auth?.publicAddress?.[0] || accounts?.[0];

        const { success, data } = await auth?.web3authlogin({
          aggregateVerifier: userInfo?.aggregateVerifier,
          appPubKey: appPubKey,
          publicAddress,
          idToken: userInfo?.idToken,
          verifierId: userInfo?.verifierId,
          web3SessionData,
        });

        if (success) {
          Storage.set('IS_CHECK', data.accessToken);
          auth.setInitialData({
            ethBalance: balance,
          });
          if (data?.inactive) {
            openModalInvalidEmail();
          } else {
            await Confirmable.open({
              content: t('login.messages.registration_successful'),
              hideCancelButton: true,
              isShowIconSuccess: true,
              acceptButtonText: t('login.go_to_login'),
            });
          }
          handleCloseModal();
        } else {
          if (data?.error === 'UNAUTHORIZED_USER_BLOCKED') {
            Confirmable.open({
              content: t('login.messages.user_not_forbidden'),
              hideCancelButton: true,
            });
          } else {
            await logoutByWeb3auth();
            Request.removeAccessToken();
            await auth.logout();
          }
        }
        await logoutByWeb3auth();
        Request.removeAccessToken();
        await auth.logout();
      } else {
        const accounts = await getAccounts();
        const { idToken } = await web3Auth.authenticateUser();

        const { success, data } = await auth?.web3authlogin({
          externalWallet: true,
          publicAddress: responseWeb3Auth?.publicAddress?.[0] || accounts?.[0],
          idToken,
          web3SessionData,
        });

        if (success) {
          Storage.set('IS_CHECK', data.accessToken);
          if (data?.inactive) {
            openModalInvalidEmail();
          } else {
            await Confirmable.open({
              content: t('login.messages.registration_successful'),
              hideCancelButton: true,
              isShowIconSuccess: true,
              acceptButtonText: t('login.go_to_login'),
            });
          }
          handleCloseModal();
        } else {
          if (data?.error === 'UNAUTHORIZED_USER_BLOCKED') {
            Confirmable.open({
              content: t('login.messages.user_not_forbidden'),
              hideCancelButton: true,
            });
          }
        }
        await logoutByWeb3auth();
        Request.removeAccessToken();
        await auth.logout();
      }
    } catch (e) {
      if (e?.code === 5111 && !window.ethereum && !Misc.isMobile) {
        Confirmable.open({
          content: (
            <>
              {t('common:for_access_from_pc')}
              <br />
              <br />
              {t('common:for_access_from_mobile')}
              <br />
              <br />
              {t('common:otherwise_error_login')}
            </>
          ),
          hideCancelButton: true,
        });
      } else if (e?.code != -32603) {
        // user reject
      } else {
        Toast.error(t('login.messages.cannot_login'));
      }
      Storage.remove('ACCESS_TOKEN');
      Storage.remove('USER_ID');
      Storage.remove('HIDE_BANNER');
      await logoutByWeb3auth();
      Request.removeAccessToken();
      await auth.logout();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      onCancel={() => {
        if (!loading) handleCloseModal();
      }}
      destroyOnClose
      className="custom-modal"
      title={null}
      padding={0}
      width={500}
    >
      <Spin spinning={loading}>
        <HeaderStyled>
          <Typography className="title">{t('login.register')}</Typography>
        </HeaderStyled>
        <ContentStyled>
          {Misc.isMobile && !window.ethereum ? (
            // <Clickable
            //   className="login-button walletconnect"
            //   onClick={() => handleLogin('wallet_connect')}
            //   disabled={loading}
            // >
            //   <img src={Images.COLOR_WALLET_CONNECT_ICON} alt="" />
            //   <Typography size="large" bold>
            //     WalletConnect
            //   </Typography>
            // </Clickable>
            <></>
          ) : (
            // <Clickable className="login-button metamask" onClick={() => handleLogin('meta_mask')} disabled={loading}>
            // <Clickable
            //   className="login-button metamask"
            //   onClick={() => handleLoginWeb3Auth(WALLET_ADAPTERS.METAMASK)}
            //   disabled={loading}
            // >
            //   <img src={Images.COLOR_METAMASK_ICON} alt="" />
            //   <Typography size="large" bold>
            //     MetaMask
            //   </Typography>
            // </Clickable>
            <></>
          )}

          {/* <div className="wrap-button">
            <div
              className="button-social google"
              onClick={() => !loading && handleLoginWeb3Auth(WALLET_ADAPTERS.OPENLOGIN, 'google')}
            >
              <img src={Images.COLOR_GOOGLE_ICON} alt="GOOGLE_ICON" />
            </div>

            <div
              className="button-social facebook"
              onClick={() => !loading && handleLoginWeb3Auth(WALLET_ADAPTERS.OPENLOGIN, 'facebook')}
            >
              <img src={Images.COLOR_FACEBOOK_ICON} alt="FACEBOOK_ICON" />
            </div>

            <div className="button-social twitter">
              <img
                src={Images.COLOR_TWITTER_ICON}
                alt="TWITTER_ICON"
                onClick={() => !loading && handleLoginWeb3Auth(WALLET_ADAPTERS.OPENLOGIN, 'twitter')}
              />
            </div>

            <div className="button-social discord">
              <img
                src={Images.COLOR_DISCORD_ICON}
                alt="DISCORD_ICON"
                onClick={() => !loading && handleLoginWeb3Auth(WALLET_ADAPTERS.OPENLOGIN, 'discord')}
              />
            </div>
          </div> */}

          {/* <Divider>{t('login.or')}</Divider> */}
          <FormEmailStyled>
            <Form.Item
              validateStatus={errors?.email && touched?.email ? 'error' : ''}
              help={<div dangerouslySetInnerHTML={{ __html: touched?.email && errors?.email ? errors?.email : '' }} />}
            >
              <Input
                className="input-email"
                prefix={<img src={Images.WHITE_EMAIL_ICON} alt="Images.WHITE_EMAIL_ICON" />}
                placeholder={t('login.enter_your_email')}
                name="email"
                onChange={text => {
                  setFieldValue && setFieldValue('email', text.target.value || '');
                }}
                value={values?.email}
                onBlur={() => setFieldTouched('email', true)}
                disabled={loading}
              />
            </Form.Item>

            <Button className="btn-login-email" disabled={!isValid || loading} onClick={handleSubmit}>
              {t('login.connect_with_email')}
            </Button>
          </FormEmailStyled>
        </ContentStyled>
      </Spin>
    </Modal>
  );
};

export default LoginModal;
