import OtpInput from 'react-otp-input';
import styled from 'styled-components';

const OTPInputStyled = styled.div`
  .otp-style {
    gap: 15px;
    justify-content: center;
    padding: 30px;

    .input-text {
      border-radius: 10px;
      font-size: 40px;
      font-weight: 700;
      padding: 10px;
      text-transform: uppercase;
      width: 70px !important;
      border: 2px solid #c7c7c7;
      background: #353535;
      color: #FFF;
    }

    span {
      color: #FFF;
    }

    @media (max-width: 575px) {
      gap: 10px;
      padding: 5px 20px;
    }
  }
`;

export const OTPInputComponent = ({ otp, setOtp }) => {
  return (
    <OTPInputStyled>
      <OtpInput
        value={otp}
        onChange={setOtp}
        numInputs={4}
        renderSeparator={<span>-</span>}
        renderInput={props => <input {...props} />}
        containerStyle="otp-style"
        inputStyle="input-text"
      />
    </OTPInputStyled>
  );
};
