import { types } from 'mobx-state-tree';

import { Model } from '../utils/mobx-model-helper';
import {
  cancelPaymentMetaMask,
  confirmPaymentMetaMask,
  createGachaMetaMaskPayment,
  getGacha,
  getGachaPaymentInfo,
  getPaidGachaTiket,
  submitGacha,
  paymentWallet,
} from '../api/gacha';

const TYPES = {
  GET_GACHA_PAYMENT_INFO: 1,
  GET_GACHA: 2,
  CREATE_GACHA_METAMASK_PAYMENT: 3,
  CONFIRM_PAYMENT_METAMASK: 4,
  GET_PAID_GACHA_TIKET: 5,
  SUBMIT_GACHA: 6,
  CANCEL_PAYMENT_METAMASK: 7,
  PAYMENT_WALLET: 8,
};

const productSummaryDTO = types.model('productSummaryDTO').props({
  gachaRarity: types.maybeNull(types.string),
  totalProduct: types.maybeNull(types.number),
  unusedProduct: types.maybeNull(types.number),
  usedProduct: types.maybeNull(types.number),
});

const GachaInfo = types.model('GachaInfo').props({
  id: types.maybeNull(types.number),
  fee: types.maybeNull(types.number),
  yenFee: types.maybeNull(types.number),
  gachaNumbers: types.maybeNull(types.array(types.number)),
  totalProduct: types.maybeNull(types.number),
  unusedProduct: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  imageUrl: types.maybeNull(types.string),
  productSummaryDTOS: types.maybeNull(types.array(productSummaryDTO)),
});

const Gacha = Model.named('Gacha')
  .props({
    model: types.maybeNull(types.number),
    gachaId: types.maybeNull(types.number),
    answerSelected: types.maybeNull(types.number),
    gachaTicketId: types.maybeNull(types.number),
    gachaInfo: types.maybeNull(GachaInfo),
  })
  .actions(self => ({
    setModel(value) {
      self.model = value;
    },
    setAnswerSelected(value) {
      self.answerSelected = value;
    },
    getGachaPaymentInfo(payload) {
      return self.request({
        type: TYPES.GET_GACHA_PAYMENT_INFO,
        api: getGachaPaymentInfo,
        payload,
      });
    },
    getGacha(payload) {
      return self.request({
        type: TYPES.GET_GACHA,
        api: getGacha,
        payload,
        onSuccess: result => {
          if (!result) return;
          self.gachaInfo = result;
        },
      });
    },
    createGachaMetaMaskPayment(payload) {
      return self.request({
        type: TYPES.CREATE_GACHA_METAMASK_PAYMENT,
        api: createGachaMetaMaskPayment,
        payload,
      });
    },
    cancelPaymentMetaMask(payload) {
      return self.request({
        type: TYPES.CANCEL_PAYMENT_METAMASK,
        api: cancelPaymentMetaMask,
        payload,
      });
    },
    confirmPaymentMetaMask(payload) {
      return self.request({
        type: TYPES.CONFIRM_PAYMENT_METAMASK,
        api: confirmPaymentMetaMask,
        payload,
      });
    },
    getPaidGachaTiket(payload) {
      return self.request({
        type: TYPES.GET_PAID_GACHA_TIKET,
        api: getPaidGachaTiket,
        payload,
        onSuccess: result => {
          self.gachaTicketId = result?.gachaHistoryId ?? null;
        },
      });
    },
    submitGacha(payload) {
      return self.request({
        type: TYPES.SUBMIT_GACHA,
        api: submitGacha,
        payload,
      });
    },
    setPaymentSuccess(payload) {
      self.gachaTicketId = 1;
    },
    paymentWallet(payload) {
      return self.request({
        type: TYPES.PAYMENT_WALLET,
        api: paymentWallet,
        payload,
      })
    },
  }));

export { TYPES };
export default Gacha.create({
  model: null,
  answerSelected: null,
  gachaTicketId: null,
  gachaInfo: null,
});
