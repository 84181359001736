import Storage from './storage';
import Request from './request';
import authStore from '../store/auth';
import { history } from '../store';
import { removeWeb3Instance } from './web3';
import Misc from './misc';
import { CHAIN_LIST } from '../constants/chains';

export const channel = new BroadcastChannel('auth', {
  type: 'localstorage',
  webWorkerSupport: true,
});

export const logout = () => {
  if (!localStorage.getItem('Web3Auth-cachedAdapter')) {
    if (Misc.isMobile) {
      removeWeb3Instance();
    }
  }

  Storage.remove('ACCESS_TOKEN');
  Storage.remove('EXPIRED_TOKEN');
  Storage.remove('REFRESH_TOKEN');
  Storage.remove('USER_ID');
  Storage.remove('PUBLIC_ADDRESS');
  Storage.remove('HIDE_BANNER');
  Storage.remove('walletconnect');
  Storage.remove('WEB3_CONNECT_CACHED_PROVIDER');
  Storage.remove('Web3Auth-cachedAdapter');
  Storage.remove('openlogin_store');
  Storage.remove('SSO_ADAPTER');
  Storage.remove('IS_CHECK');
  Storage.remove('ACCESS_TOKEN_WALLET');
  Storage.remove('PUBLIC_ADDRESS_WALLET');
  Storage.remove('USER_ID_WALLET');
  Storage.remove('EXPIRED_TOKEN_WALLET');

  Request.removeAccessToken();

  authStore.logout();
  channel.postMessage('LOGOUT');
  history.replace('/');
};

export const getNetworksEnv = env => Object.values(CHAIN_LIST).find(item => item.env?.includes(env))?.env;

export const getNetworks = env => Object.values(CHAIN_LIST).filter(item => item.env?.includes(env));

export const getNetworkSelected = (env, chainIdSelected) => {
  const networks = getNetworks(env);
  return chainIdSelected ? networks.find(item => item.chainId === +chainIdSelected) || networks[1] : networks[1];
};
