import { MainApi } from './endpoint';

export function getGacha() {
  return MainApi.get(`/user/gacha/running`);
}

export function getGachaHistory() {
  return MainApi.get('/user/gacha/history');
}

// get infor to payment credit/slash
export function getGachaPaymentInfo(payload) {
  return MainApi.post('/user/gacha/payment', payload);
}

// cancel payment to credit/slash
export function cancelPayment({ gachaHistoryId }) {
  return MainApi.post('/user/gacha/payment/cancel', { gachaHistoryId });
}

// get gacha info (gachaHistoryId, gachaNumbers to select)
export function getGachaInfo({ gachaId }) {
  return MainApi.get('/user/gacha/play', { gachaId });
}

// create payment request by metamask
export function createGachaMetaMaskPayment(payload) {
  return MainApi.post('/user/gacha/purchase', payload);
}

// cancel payment request by metamask
export function cancelPaymentMetaMask({ gachaHistoryId }) {
  return MainApi.post('/user/gacha/purchase/cancel', { gachaHistoryId });
}

// confirm payment request by metamask
export function confirmPaymentMetaMask(payload) {
  return MainApi.post('/user/gacha/purchase/confirm', payload);
}

// get gacha tiket id
export function getPaidGachaTiket() {
  return MainApi.get('/user/gacha/payment-status');
}

// confirm payment request by metamask
export function submitGacha(payload) {
  return MainApi.post('/user/gacha/submit', payload);
}

export function paymentWallet(payload) {
  return MainApi.post(`/user/gacha/wallet-purchase`, payload)
}

