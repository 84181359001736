import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'mobx-react';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import lodash from 'lodash';

// import Configs from '../configs';
import i18n from '../translations/i18n';
import ui from './ui';
import assets from './assets';
import auth from './auth';
import products from './products';
import payment from './payment';
import users from './users';
import events from './events';
import bid from './bid';
import sell from './sell';
import identities from './identities';
import offers from './offers';
import banner from './banners';
import categories from './categories';
import creators from './creators';
import news from './news';
import lending from './lending';
import gacha from './gacha';
import staking from './staking';

export const history = createBrowserHistory({ basename: process.env.REACT_APP_BASE_NAME });

const { push, replace } = history;

history.push = (path, state) => {
  push(lodash.isObject(path) ? path : `/${i18n.language}${path}`, state);
};

history.replace = (path, state) => {
  replace(lodash.isObject(path) ? path : `/${i18n.language}${path}`, state);
};

const stores = {
  ui,
  assets,
  auth,
  products,
  payment,
  users,
  events,
  bid,
  sell,
  identities,
  offers,
  banner,
  categories,
  creators,
  news,
  lending,
  gacha,
  staking,
};

const AppProvider = ({ children }) => (
  <Provider {...stores}>
    <Router history={history}>{children}</Router>
  </Provider>
);

AppProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default AppProvider;
