import { types } from 'mobx-state-tree';
import moment from 'moment';

import { Model } from '../utils/mobx-model-helper';
import {
  getProducts,
  getEventProducts,
  getMyProducts,
  getMyProductsWithNoPaging,
  getMyBidProducts,
  getProductDetails,
  getProductCategories,
  getProductFilterData,
  getPriceRate,
  getPriceChartData,
  getTransactionHistories,
  favorite,
  getMyTransactionHistories,
  getProductsWish,
  getAuthorProductPrice,
  getProductPrice,
  getCultureOverview,
  getProductItems,
  getProductErc1155,
  getProductByCreatorId,
  approveForAllProduct,
  exchangeRegister,
  exchangeConfirm,
  requestParentToken,
  getCurrencyProduct,
  getAvatarPreview,
} from '../api/products';
import { MAX_LENGTH_INPUT_VALUE_FRACTION } from '../constants/common';

const TYPES = {
  GET_PRODUCTS: 1,
  GET_EVENT_PRODUCTS: 2,
  GET_MY_PRODUCTS: 3,
  GET_MY_BID_PRODUCTS: 4,
  GET_PRODUCT_DETAILS: 5,
  GET_PRODUCT_CATEGORIES: 6,
  GET_PRODUCT_FILTER_DATA: 7,
  GET_PRICE_RATE: 8,
  GET_MY_SELL_PRODUCTS: 9,
  GET_MY_SOLD_PRODUCTS: 10,
  GET_PRICE_CHART_DATA: 11,
  GET_TRANSACTION_HISTORIES: 12,
  GET_SEARCH_PRODUCTS: 13,
  FAVORITE: 14,
  GET_HOT_RANKING_PRODUCTS: 15,
  GET_RECOMMENDED_PRODUCTS: 16,
  GET_MY_TRANSACTION_HISTORIES: 17,
  GET_PRODUCTS_WISH: 18,
  GET_MY_OFFER_PRODUCTS: 19,
  GET_PRODUCT_PRICE: 20,
  GET_AUTHOR_PRODUCT_PRICE: 21,
  GET_CULTURE_OVERVIEW: 22,
  GET_PRODUCT_ITEMS: 23,
  GET_PRODUCT_ERC1155: 25,
  GET_PRODUCT_BY_CREATOR_ID: 26,
  GET_CURRENCY_PRODUCT: 27,
  GET_AVATAR_PREVIEW: 28,
};

const NEW_RANGE_HOURS = 24;

const Category = types.model('Category').props({
  id: types.identifierNumber,
  name: types.string,
  value: types.maybeNull(types.string),
  image: types.maybeNull(types.string),
});

const BidsHistory = types.model('BidsHistory').props({
  bidPrice: types.maybeNull(types.number),
  buyingFlag: types.maybeNull(types.boolean),
  createdAt: types.maybeNull(types.number),
  id: types.maybeNull(types.string),
  productBidHistoryId: types.maybeNull(types.number),
  productBidId: types.maybeNull(types.number),
  productId: types.maybeNull(types.number),
  status: types.boolean,
  userAvatar: types.maybeNull(types.string),
  userBidStatus: types.maybeNull(types.string),
  userId: types.maybeNull(types.number),
  userName: types.maybeNull(types.string),
  userPublicAddress: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
});

const ProductBid = types
  .model('ProductBid')
  .props({
    status: types.maybeNull(types.string),
    startTime: types.maybeNull(types.number),
    endTime: types.maybeNull(types.number),
    approvePublicKey: types.maybeNull(types.string),
    biggestBidPrice: types.maybeNull(types.number),
    biggestBidPriceYen: types.maybeNull(types.number),
    checkUserBid: types.maybeNull(types.boolean),
    expectPrice: types.maybeNull(types.number),
    expectPriceDisplay: types.maybeNull(types.number),
    id: types.maybeNull(types.number),
    otherUserBidProduct: types.maybeNull(types.boolean),
    startPrice: types.maybeNull(types.number),
    yenStartPrice: types.maybeNull(types.number),
    yenExpectPrice: types.maybeNull(types.number),
    yenExpectPriceDisplay: types.maybeNull(types.number),
    transferNBNGStatus: types.maybeNull(types.string),
    transferTokenStatus: types.maybeNull(types.string),
    transferUSDTStatus: types.maybeNull(types.string),
    currency: types.maybeNull(types.string),
    offerNftTransactionStatus: types.maybeNull(types.string),
  })
  .actions(self => ({
    setData(data) {
      Object.keys(data).forEach(key => {
        self[key] = data[key];
      });
    },
  }));

const ProductSell = types
  .model('ProductSell')
  .props({
    resellCancelable: types.boolean,
    confirmResell: types.boolean,
    productBuying: types.boolean,
    reselling: types.boolean,
  })
  .actions(self => ({
    setData(data) {
      Object.keys(data).forEach(key => {
        self[key] = data[key];
      });
    },
  }));

const lendingDetailDTO = types.model('lendingDetailDTO').props({
  userId: types.maybeNull(types.number),
  lenderId: types.maybeNull(types.number),
  borrowerId: types.maybeNull(types.number),
  lendingTitle: types.maybeNull(types.string),
  pricePerDay: types.maybeNull(types.string),
  rentalDuration: types.maybeNull(types.number),
  rentalDurationEndTime: types.maybeNull(types.number),
  rentalDurationStartTime: types.maybeNull(types.number),
  offerExpirationStartTime: types.maybeNull(types.number),
  offerExpirationEndTime: types.maybeNull(types.number),
  offerExpiration: types.maybeNull(types.number),
  borrowDurationStartTime: types.maybeNull(types.number),
  borrowDurationEndTime: types.maybeNull(types.number),
  borrowDuration: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
});

const borrowDetailDTO = types.model('borrowDetailDTO').props({
  userId: types.maybeNull(types.number),
  lenderId: types.maybeNull(types.number),
  borrowerId: types.maybeNull(types.number),
  lendingTitle: types.maybeNull(types.string),
  pricePerDay: types.maybeNull(types.string),
  rentalDuration: types.maybeNull(types.number),
  rentalDurationEndTime: types.maybeNull(types.number),
  rentalDurationStartTime: types.maybeNull(types.number),
  offerExpirationStartTime: types.maybeNull(types.number),
  offerExpirationEndTime: types.maybeNull(types.number),
  offerExpiration: types.maybeNull(types.number),
  borrowDurationStartTime: types.maybeNull(types.number),
  borrowDurationEndTime: types.maybeNull(types.number),
  borrowDuration: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  userName: types.maybeNull(types.string),
});

const ProductDetails = types
  .model('ProductDetails')
  .props({
    id: types.identifierNumber,
    bidFlag: types.maybeNull(types.boolean),
    contractAddress: types.maybeNull(types.string),
    countView: types.maybeNull(types.number),
    countFavorite: types.maybeNull(types.number),
    isUserFavorited: types.maybeNull(types.boolean),
    currency: types.maybeNull(types.string),
    currentTime: types.maybeNull(types.number),
    description: types.maybeNull(types.string),
    displayStatus: types.maybeNull(types.string),
    holding: types.maybeNull(types.boolean),
    imageUrl: types.maybeNull(types.string),
    license: types.maybeNull(types.boolean),
    licenseId: types.maybeNull(types.number),
    name: types.maybeNull(types.string),
    owned: types.maybeNull(types.boolean),
    ownerAddress: types.maybeNull(types.string),
    ownerName: types.maybeNull(types.string),
    paymentAddress: types.maybeNull(types.string),
    price: types.maybeNull(types.number),
    rareFlg: types.maybeNull(types.boolean),
    newAuctionFlag: types.maybeNull(types.boolean),
    status: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    tokenId: types.maybeNull(types.number),
    transactionId: types.maybeNull(types.string),
    typeSale: types.maybeNull(types.string),
    userId: types.maybeNull(types.number),
    yenPrice: types.maybeNull(types.number),
    categories: types.array(
      types.model({
        name: types.maybeNull(types.string),
        value: types.maybeNull(types.string),
      }),
    ),
    imageType: types.maybeNull(types.string),
    productBid: types.maybeNull(ProductBid),
    productResell: types.maybeNull(ProductSell),
    itemType: types.maybeNull(types.string),
    creator: types.maybeNull(types.string),
    rarity: types.maybeNull(types.number),
    productAttributes: types.array(
      types.model({
        id: types.maybeNull(types.number),
        name: types.maybeNull(types.string),
        value: types.maybeNull(types.string),
        type: types.maybeNull(types.string),
        attribute: types.maybeNull(types.number),
      }),
    ),
    tokenType: types.maybeNull(types.string),
    reselling: types.maybeNull(types.boolean),
    productOwnerRole: types.maybeNull(types.string),
    offerNftTransactionStatus: types.maybeNull(types.string),
    canOfferFlag: types.maybeNull(types.boolean),
    isAllowOffer: types.maybeNull(types.boolean),
    offerHighestPrice: types.maybeNull(types.number),
    offerHighestPriceCurrency: types.maybeNull(types.string),
    isApproveOffer: types.maybeNull(types.boolean),
    user: types.model({
      userId: types.maybeNull(types.number),
      authenMetamask: types.maybeNull(types.string),
      bio: types.maybeNull(types.string),
      coverImage: types.maybeNull(types.string),
      createdAt: types.maybeNull(types.number),
      email: types.maybeNull(types.string),
      heatCount: types.maybeNull(types.number),
      infoReqFlg: types.maybeNull(types.boolean),
      name: types.maybeNull(types.string),
      nonce: types.maybeNull(types.number),
      publicAddress: types.maybeNull(types.string),
      status: types.maybeNull(types.string),
      userImage: types.maybeNull(types.string),
      wishCount: types.maybeNull(types.number),
    }),
    wishCount: types.maybeNull(types.number),
    isUserWished: types.maybeNull(types.boolean),
    categoryName: types.maybeNull(types.string),
    tokenStandard: types.maybeNull(types.string),
    totalQuantity: types.maybeNull(types.number),
    ownerQuantity: types.maybeNull(types.number),
    holdingQuantity: types.maybeNull(types.number),
    remainQuantity: types.maybeNull(types.number),
    canResellERC1155: types.maybeNull(types.boolean),
    productBuying: types.maybeNull(types.boolean),
    totalCreditChargePrice: types.maybeNull(types.number),
    creatorName: types.maybeNull(types.string),
    metaMaskImageUrl: types.maybeNull(types.string),
    typeAnimation: types.maybeNull(types.string),
    chainId: types.maybeNull(types.number),
    ownerAvatar: types.maybeNull(types.string),
    lendingStatus: types.maybeNull(types.string),
    lendingTitle: types.maybeNull(types.string),
    lendingDetailDTO: types.maybeNull(lendingDetailDTO),
    borrowDetailDTO: types.maybeNull(borrowDetailDTO),
    nftCategory: types.maybeNull(types.number),
    nftSubType: types.maybeNull(types.number),
    nftType: types.maybeNull(types.number),
    minimumOfferPrice: types.maybeNull(types.number),
    stakingStatus: types.maybeNull(types.string),
  })
  .views(self => ({
    get isCheckoutValid() {
      return self?.status === 'SALE' && self.displayStatus === 'PUBLIC' && !self?.holding;
    },

    get isBidValid() {
      return (
        self.productBid?.status === 'NEW' &&
        self.bidFlag &&
        self?.status === 'SALE' &&
        self.displayStatus === 'PUBLIC' &&
        self.typeSale === 'LIMITED'
      );
    },

    get isBidTimeValid() {
      const currentTime = moment(self.currentTime);
      const startTime = moment(self.productBid.startTime);
      const endTime = moment(self.productBid.endTime);

      const isTimeValid = currentTime.diff(startTime, 's') >= 0 && endTime.diff(currentTime, 's') > 0;

      return isTimeValid;
    },

    get isCancelBidTimeValid() {
      const currentTime = moment(self.currentTime);
      const startTime = moment(self.productBid.startTime);
      const endTime = moment(self.productBid.endTime);

      const isTimeValid = currentTime.diff(startTime, 's') >= 0 && endTime.diff(currentTime, 's') > 600;

      return isTimeValid;
    },

    get isSellValid() {
      return self.displayStatus === 'PUBLIC';
    },

    get isParent() {
      return self.tokenType === 'MULTI';
    },

    get isMulti() {
      return self.parentProductId || self.tokenType === 'MULTI';
    },
  }))
  .actions(self => ({
    setHolding(value) {
      self.holding = value;
    },

    setData(data) {
      Object.keys(data).forEach(key => {
        self[key] = data[key];
      });
    },
  }));

const Product = types
  .model('Product')
  .props({
    id: types.identifierNumber,
    licenseId: types.maybeNull(types.number),
    licenseAddress: types.maybeNull(types.string),
    licenseFee: types.maybeNull(types.number),
    imageUrl: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    price: types.maybeNull(types.number),
    currentTime: types.maybeNull(types.number),
    yenPrice: types.maybeNull(types.number),
    status: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    userId: types.maybeNull(types.number),
    backgroundColor: types.maybeNull(types.string),
    itemColor: types.maybeNull(types.string),
    imageType: types.maybeNull(types.string),
    size: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    currency: types.maybeNull(types.string),
    holding: types.maybeNull(types.boolean),
    rareFlg: types.maybeNull(types.boolean),
    newAuctionFlag: types.maybeNull(types.boolean),
    isNew: types.maybeNull(types.boolean),
    productBid: types.maybeNull(
      types.model({
        status: types.maybeNull(types.string),
        startTime: types.maybeNull(types.number),
        endTime: types.maybeNull(types.number),
        approvePublicKey: types.maybeNull(types.string),
        biggestBidPrice: types.maybeNull(types.number),
        biggestBidPriceYen: types.maybeNull(types.number),
        checkUserBid: types.maybeNull(types.boolean),
        expectPrice: types.maybeNull(types.number),
        expectPriceDisplay: types.maybeNull(types.number),
        id: types.maybeNull(types.number),
        otherUserBidProduct: types.maybeNull(types.boolean),
        startPrice: types.maybeNull(types.number),
        yenStartPrice: types.maybeNull(types.number),
        yenExpectPrice: types.maybeNull(types.number),
        yenExpectPriceDisplay: types.maybeNull(types.number),
        transferNBNGStatus: types.maybeNull(types.string),
        transferTokenStatus: types.maybeNull(types.string),
        transferUSDTStatus: types.maybeNull(types.string),
        currency: types.maybeNull(types.string),
        offerNftTransactionStatus: types.maybeNull(types.string),
      }),
    ),
    countView: types.maybeNull(types.number),
    countFavorite: types.maybeNull(types.number),
    ageRestricted: types.maybeNull(types.boolean),
    productAttributes: types.maybeNull(
      types.array(
        types.model({
          id: types.maybeNull(types.number),
          name: types.maybeNull(types.string),
          value: types.maybeNull(types.string),
          type: types.maybeNull(types.string),
          attribute: types.maybeNull(types.number),
        }),
      ),
    ),
    itemType: types.maybeNull(types.string),
    series: types.maybeNull(types.string),
    creator: types.maybeNull(types.string),
    rarity: types.maybeNull(types.number),
    idolName: types.maybeNull(types.string),
    imageThumbnailUrls: types.array(types.maybeNull(types.string)),
    tokenType: types.maybeNull(types.string),
    aboutOfferRatio: types.maybeNull(types.string),
    reselling: types.maybeNull(types.boolean),
    productOwnerRole: types.maybeNull(types.string),
    tokenId: types.maybeNull(types.number),
    offerNftTransactionStatus: types.maybeNull(types.string),
    canOfferFlag: types.maybeNull(types.boolean),
    isAllowOffer: types.maybeNull(types.boolean),
    offerHighestPrice: types.maybeNull(types.number),
    offerHighestPriceCurrency: types.maybeNull(types.string),
    paymentAddress: types.maybeNull(types.string),
    cultureNickname: types.maybeNull(types.string),
    countOffer: types.maybeNull(types.number),
    cultureType: types.maybeNull(types.string),
    volume: types.maybeNull(types.number),
    categoryName: types.maybeNull(types.string),
    wishCount: types.maybeNull(types.number),
    canResellERC1155: types.maybeNull(types.boolean),
    creatorName: types.maybeNull(types.string),
    isUserWished: types.maybeNull(types.boolean),
    lendingStatus: types.maybeNull(types.string),
    chainId: types.maybeNull(types.number),
    lendingDetailDTO: types.maybeNull(lendingDetailDTO),
    borrowDetailDTO: types.maybeNull(borrowDetailDTO),
    stakingStatus: types.maybeNull(types.string),
  })
  .actions(self => ({
    setData(data) {
      Object.keys(data).forEach(key => {
        self[key] = data[key];
      });
    },
  }));

const Products = types.model('Products').props({
  items: types.array(Product),
  page: types.maybeNull(types.number),
  total: types.maybeNull(types.number),
});

const ProductsStore = Model.named('ProductsStore')
  .props({
    categories: types.array(Category),
    topProducts: Products,
    searchProducts: Products,
    hightlightEventProduct: types.maybeNull(Product),
    eventProducts: Products,
    myProducts: Products,
    mySellProducts: types.array(Product),
    mySoldProducts: types.array(Product),
    myBidProducts: Products,
    currentProductDetails: types.maybeNull(ProductDetails),
    hotRankingProducts: Products,
    recommendedProducts: Products,
    myProductsWish: Products,
    bidsHistory: types.array(BidsHistory),
    firstTwoBidHistories: types.array(BidsHistory),
    randomNumber: types.maybeNull(types.number),
    gachaPickupProducts: types.array(Product),
    myOfferProducts: types.array(Product),
    productItems: Products,
    newProducts: Products,
    auctionProduct: Products,
    erc1155Product: Products,
    creatorProducts: Products,
    minimumOfferPrice: types.maybeNull(types.number),
    stakingStatus: types.maybeNull(types.string),
  })
  .actions(self => ({
    setBidsHistory(payload) {
      return (self.bidsHistory = payload);
    },

    setFirstTwoBidHistories(payload) {
      return (self.firstTwoBidHistories = payload);
    },

    regenerateRandomNumber() {
      self.randomNumber = Math.floor(Math.random() * 999);
    },

    getTopProducts(payload) {
      return self.request({
        type: TYPES.GET_PRODUCTS,
        api: getProducts,
        payload,
        // onSuccess: result => {
        //   const items = self.parseProducts(result.result);
        //   // console.log({ items });

        //   self.topProducts = {
        //     items,
        //     page: 1,
        //     total: result.total,
        //   };
        // },
      });
    },

    getNewProducts(payload) {
      return self.request({
        type: TYPES.GET_PRODUCTS,
        api: getProducts,
        payload,
        // onSuccess: result => {
        //   const items = self.parseProducts(result.result);

        //   self.newProducts = {
        //     items,
        //     page: 1,
        //     total: result.total,
        //   };
        // },
      });
    },

    getSearchProducts(payload, { concat }) {
      return self.request({
        type: TYPES.GET_SEARCH_PRODUCTS,
        api: getProducts,
        payload,
        onSuccess: result => {
          const items = self.parseProducts(result.result);

          self.searchProducts = {
            items: concat ? self.searchProducts.items.concat(items) : items,
            page: result.page,
            total: result.total,
          };
        },
      });
    },

    getEventProducts(payload, { concat }) {
      return self.request({
        type: TYPES.GET_EVENT_PRODUCTS,
        api: getEventProducts,
        payload,
        onSuccess: result => {
          let items = self.parseProducts(result.result);

          if (result.page === 1 && items[0]) {
            // eslint-disable-next-line prefer-destructuring
            self.hightlightEventProduct = {
              ...items[0],
              description: null,
            };
          }
          if (result.page === 1) {
            items = items.slice(1, items.length);
          }

          self.eventProducts = {
            items: concat ? self.eventProducts.items.concat(items) : items,
            page: result.page,
            total: result.total,
          };
        },
      });
    },

    getMyProducts(payload, { concat }) {
      return self.request({
        type: TYPES.GET_MY_PRODUCTS,
        api: getMyProducts,
        payload: {
          ...payload,
          findType: 'OWNED',
        },
        onSuccess: result => {
          const items = self.parseProducts(result.result);

          self.myProducts = {
            items: concat ? self.myProducts.items.concat(items) : items,
            page: result.page,
            total: result.total,
          };
        },
      });
    },

    getMySellProducts(payload) {
      return self.request({
        type: TYPES.GET_MY_SELL_PRODUCTS,
        api: getMyProductsWithNoPaging,
        payload: {
          ...payload,
          findType: 'RESELL',
        },
        onSuccess: result => {
          const items = self.parseProducts(result);

          self.mySellProducts = items;
        },
      });
    },

    getMySoldProducts(payload) {
      return self.request({
        type: TYPES.GET_MY_SOLD_PRODUCTS,
        api: getMyProductsWithNoPaging,
        payload: {
          ...payload,
          findType: 'SOLD',
        },
        onSuccess: result => {
          const items = self.parseProducts(result);

          self.mySoldProducts = items;
        },
      });
    },

    getMyBidProducts(payload, { concat }) {
      return self.request({
        type: TYPES.GET_MY_BID_PRODUCTS,
        api: getMyBidProducts,
        payload,
        onSuccess: result => {
          const items = self.parseProducts(result.result);

          self.myBidProducts = {
            items: concat ? self.myBidProducts.items.concat(items) : items,
            page: result.page,
            total: result.total,
          };
        },
      });
    },

    getProductDetails(payload) {
      return self.request({
        type: TYPES.GET_PRODUCT_DETAILS,
        api: getProductDetails,
        payload,
        onSuccess: result => {
          if (!result) return null;
          self.currentProductDetails = {
            ...result,
            license: !!result.license,
            categories: result.productAttributes.filter(attribute => attribute.type === 'CATEGORY'),
            backgroundColor: result.productAttributes.find(attribute => attribute.type === 'COLOR')?.value,
            imageType: result.productAttributes.find(attribute => attribute.type === 'IMAGE_TYPE')?.name,
          };

          if (result?.productBid?.startPrice) {
            self.currentProductDetails.productBid.startPrice =
              Math.round(result.productBid?.startPrice * `1e${MAX_LENGTH_INPUT_VALUE_FRACTION}`) /
              `1e${MAX_LENGTH_INPUT_VALUE_FRACTION}`;

            if (result?.productBid?.biggestBidPrice) {
              self.currentProductDetails.productBid.biggestBidPrice =
                Math.round(result.productBid?.biggestBidPrice * `1e${MAX_LENGTH_INPUT_VALUE_FRACTION}`) /
                `1e${MAX_LENGTH_INPUT_VALUE_FRACTION}`;
            }
          }

          return null;
        },
      });
    },

    getProductCategories(payload) {
      return self.request({
        type: TYPES.GET_PRODUCT_CATEGORIES,
        api: getProductCategories,
        payload,
        onSuccess: result => {
          self.categories = result;
        },
      });
    },

    getProductFilterData(payload) {
      return self.request({
        type: TYPES.GET_PRODUCT_FILTER_DATA,
        api: getProductFilterData,
        payload,
      });
    },

    getPriceRate(payload) {
      return self.request({
        type: TYPES.GET_PRICE_RATE,
        api: getPriceRate,
        payload,
      });
    },

    getPriceChartData(payload) {
      return self.request({
        type: TYPES.GET_PRICE_CHART_DATA,
        api: getPriceChartData,
        payload,
      });
    },

    getTransactionHistories(payload) {
      return self.request({
        type: TYPES.GET_TRANSACTION_HISTORIES,
        api: getTransactionHistories,
        payload,
      });
    },

    favorite(payload) {
      return self.request({
        type: TYPES.FAVORITE,
        api: favorite,
        payload,
      });
    },

    parseProducts(items) {
      return items.map(item => ({
        ...item,
        isNew: moment(item.currentTime).diff(moment(item.createdAt), 'h') <= NEW_RANGE_HOURS,
      }));
    },

    getHotRankingProducts(payload) {
      return self.request({
        type: TYPES.GET_HOT_RANKING_PRODUCTS,
        api: getProducts,
        payload,
        onSuccess: result => {
          const items = self.parseProducts(result.result);

          self.hotRankingProducts = {
            items,
            page: 1,
            total: result.total,
          };
        },
      });
    },

    getRecommendedProducts(payload) {
      return self.request({
        type: TYPES.GET_RECOMMENDED_PRODUCTS,
        api: getProducts,
        payload,
        onSuccess: result => {
          const items = self.parseProducts(result.result);

          self.recommendedProducts = {
            items,
            page: 1,
            total: result.total,
          };
        },
      });
    },
    getMyTransactionHistories(payload) {
      return self.request({
        type: TYPES.GET_MY_TRANSACTION_HISTORIES,
        api: getMyTransactionHistories,
        payload,
      });
    },
    getProductsWish(payload, { concat }) {
      return self.request({
        type: TYPES.GET_PRODUCTS_WISH,
        api: getProductsWish,
        payload,
        onSuccess: result => {
          const items = self.parseProducts(result?.result);

          self.myProductsWish = {
            items: concat ? self.myProductsWish?.items.concat(items) : items,
            page: result?.page,
            total: result?.total,
          };
        },
      });
    },

    approveForAllProduct(payload) {
      return self.request({
        type: TYPES.APPROVE_FOR_ALL,
        api: approveForAllProduct,
        payload,
      });
    },

    exchangeRegister(payload) {
      return self.request({
        type: TYPES.EXCHANGE_REGISTER,
        api: exchangeRegister,
        payload,
      });
    },

    exchangeConfirm(payload) {
      return self.request({
        type: TYPES.EXCHANGE_CONFIRM,
        api: exchangeConfirm,
        payload,
      });
    },

    requestParentToken(payload) {
      return self.request({
        type: TYPES.REQUEST_PARENT_TOKEN,
        api: requestParentToken,
        payload,
      });
    },

    getMyOfferProducts(payload) {
      return self.request({
        type: TYPES.GET_MY_OFFER_PRODUCTS,
        api: getMyProductsWithNoPaging,
        payload: {
          ...payload,
          findType: 'OFFER',
        },
        onSuccess: result => {
          const items = self.parseProducts(result);

          self.myOfferProducts = items;
        },
      });
    },

    getProductPrice(payload) {
      return self.request({
        type: TYPES.GET_PRODUCT_PRICE,
        api: getProductPrice,
        payload,
      });
    },

    getAuthorProductPrice(payload) {
      return self.request({
        type: TYPES.GET_AUTHOR_PRODUCT_PRICE,
        api: getAuthorProductPrice,
        payload,
      });
    },

    getCultureOverview(payload) {
      return self.request({
        type: TYPES.GET_CULTURE_OVERVIEW,
        api: getCultureOverview,
        payload,
      });
    },

    getProductItems(payload, { concat }) {
      return self.request({
        type: TYPES.GET_PRODUCT_ITEMS,
        api: getProductItems,
        payload,
        onSuccess: result => {
          const items = self.parseProducts(result.result);

          self.productItems = {
            items: concat ? self.productItems.items.concat(items) : items,
            page: result.page,
            total: result.total,
          };
        },
      });
    },

    getAuctionProduct(payload) {
      return self.request({
        type: TYPES.GET_PRODUCT_ITEMS,
        api: getProductItems,
        payload,
        onSuccess: result => {
          self.auctionProduct = {
            items: result.result,
            page: result.page,
            total: result.total,
          };
        },
      });
    },

    getProductErc1155(payload, { concat }) {
      return self.request({
        type: TYPES.GET_PRODUCT_ERC1155,
        api: getProductErc1155,
        payload,
        onSuccess: result => {
          const items = self.parseProducts(result.result);

          self.erc1155Product = {
            items: concat ? self.erc1155Product.items.concat(items) : items,
            page: result.page,
            total: result.total,
          };
        },
      });
    },

    getProductByCreatorId(payload, { concat }) {
      return self.request({
        type: TYPES.GET_PRODUCT_BY_CREATOR_ID,
        api: getProductByCreatorId,
        payload,
        onSuccess: result => {
          const items = self.parseProducts(result.result);

          self.creatorProducts = {
            items: concat ? self.creatorProducts.items.concat(items) : items,
            page: result.page,
            total: result.total,
          };
        },
      });
    },

    getCurrencyProduct(payload) {
      return self.request({
        type: TYPES.GET_CURRENCY_PRODUCT,
        api: getCurrencyProduct,
        payload,
      });
    },

    getAvatarPreview(payload) {
      return self.request({
        type: TYPES.GET_AVATAR_PREVIEW,
        api: getAvatarPreview,
        payload,
      });
    },
  }));

export { TYPES };
export default ProductsStore.create({
  topProducts: {
    items: [],
    page: 1,
    total: 0,
  },
  searchProducts: {
    items: [],
    page: 1,
    total: 0,
  },
  eventProducts: {
    items: [],
    page: 1,
    total: 0,
  },
  myProducts: {
    items: [],
    page: 1,
    total: 0,
  },
  mySellProducts: [],
  mySoldProducts: [],
  myBidProducts: {
    items: [],
    page: 1,
    total: 0,
  },
  currentProductDetails: null,
  hotRankingProducts: {
    items: [],
    page: 1,
    total: 0,
  },
  recommendedProducts: {
    items: [],
    page: 1,
    total: 0,
  },
  myProductsWish: {
    items: [],
    page: 1,
    total: 0,
  },
  myOfferProducts: [],
  productItems: {
    items: [],
    page: 1,
    total: 0,
    pages: 1,
  },
  newProducts: {
    items: [],
    page: 1,
    total: 0,
  },
  auctionProduct: {
    items: [],
    page: 1,
    total: 0,
  },
  erc1155Product: {
    items: [],
    page: 1,
    total: 0,
  },
  creatorProducts: {
    items: [],
    page: 1,
    total: 0,
  },
});
