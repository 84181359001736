import { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import lodash from 'lodash';
import moment from 'moment';

import Request from '../utils/request';
import Storage from '../utils/storage';
// import { logout } from '../utils/auth';
import authStore from '../store/auth';
// import { getBalanceNew } from '../utils/web3';
// import Misc from '../utils/misc';
import i18n from '../translations/i18n';
// import { useAuth } from '../hooks/auth';
import { useWeb3Auth } from '../contexts/web3auth/web3auth';
import Loading from '../components/loading';
import styled from 'styled-components';
import { Colors } from '../theme';
import { MobXProviderContext } from 'mobx-react';
import useModalInvalidEmail from '../hooks/useModalInvalidEmail';
import { channel } from '../utils/auth';

yup.setLocale({
  mixed: {
    required: 'required',
  },
  string: {
    email: 'email',
  },
});

const Init = ({ children }) => {
  // const { onLoginAction } = useAuth();
  const { open: openModalInvalidEmail } = useModalInvalidEmail();
  const { products: productsStore, auth } = useContext(MobXProviderContext);
  const { getAccounts, isLoading, refreshToken, expiredToken } = useWeb3Auth();
  const [initing] = useState(true);
  // const [initing, setIniting] = useState(true);

  const fetchPriceRate = async () => {
    const token = Storage.get('ACCESS_TOKEN');
    const tokenWallet = Storage.get('ACCESS_TOKEN_WALLET');
    if (token && !tokenWallet) {
      handleCrossSite(token);
    } else if (tokenWallet) {
      await auth.getBalanceWallet(tokenWallet);
    }
    const priceRateResult = await productsStore.getPriceRate();

    const priceRates = lodash.reduce(
      priceRateResult?.data,
      (result, item) => {
        result[item?.name] = +item?.value;
        return result;
      },
      {},
    );

    if (priceRateResult.success) {
      authStore.setInitialData({
        ...authStore.initialData,
        ...priceRates,
      });
    }
  };

  const handleSubmitCrossSite = async ({ accounts, identifier }) => {
    const { data, success } = await auth.loginSSOWalletSignIn({
      auth_token: accounts,
      identifier: identifier,
    });
    if (success) {
      Storage.set('ACCESS_TOKEN_WALLET', data.access_token);
      Storage.set('PUBLIC_ADDRESS_WALLET', data.public_address);
      Storage.set('USER_ID_WALLET', data.user_id);
      Storage.set('EXPIRED_TOKEN_WALLET', data.expired_at);
      await auth.getBalanceWallet(data.access_token);
    }
  };

  const handleCrossSite = async isLogin => {
    if (isLogin) {
      const { success, data } = await auth.retrieveAccount();
      if (success) {
        handleSubmitCrossSite({
          accounts: data.accounts[0].auth_token,
          identifier: data.accounts[0].identifier,
        });
      }
    }
  };

  // const fetchBalanceForMobile = async address => {
  //   const { native } = await getBalanceNew(address);

  //   authStore.setBalance(native || 0);
  // };


  useEffect(() => {
    channel.addEventListener('message', async (event) => {
      if (event?.data === 'LOGIN' || event?.data === 'LOGOUT') {
        window.location.reload();
      }
    });
    return () => {
      channel.close();
    };
  }, []);

  // web3auth
  useEffect(() => {
    const initFunc = async () => {
      const token = Storage.get('ACCESS_TOKEN');
      const userId = Storage.get('USER_ID');
      const publicAddress = Storage.get('PUBLIC_ADDRESS');

      if (!token || !userId) return;
      Request.setAccessToken(token);
      try {
        const accounts = (await getAccounts()?.[0]) || publicAddress;
        if (token && accounts?.length) {
          authStore.setInitialData({
            // ethBalance: balance,
            publicAddress: accounts,
            userId: userId,
          });
          const response = await authStore.getInitialData({ headers: token });
          if (response.success) {
            authStore.setLoggedIn(true);
            await fetchPriceRate();
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };

    initFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccounts]);

  const callFuncInit = async headers => {
    const { data } = await authStore.getInitialData({ headers });
    if (data?.isInActive) {
      openModalInvalidEmail();
    }
  };

  const refreshAccessToken = useCallback(async () => {
    const { success, data } = await auth.refreshToken({ refresh_token: refreshToken });

    if (success) {
      Storage.set('ACCESS_TOKEN', data.accessToken);
      Storage.set('EXPIRED_TOKEN', data.expiredAt);
      Storage.set('REFRESH_TOKEN', data.refreshToken);
      Request.setAccessToken(data.accessToken);
    }
  }, [auth, refreshToken]);

  const isTokenExpiring = () => {
    if (!expiredToken) {
      return false;
    }
    const timeDifference = Number(expiredToken) - moment.utc().unix() * 1000;
    const oneDayMinutes = 86400000;
    return timeDifference <= oneDayMinutes;
  };

  useEffect(() => {
    if (isTokenExpiring()) {
      refreshAccessToken();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshAccessToken]);

  useEffect(() => {
    const IS_CHECK = Storage.get('IS_CHECK');
    const ACCESS_TOKEN = Storage.get('ACCESS_TOKEN');

    if (ACCESS_TOKEN || IS_CHECK) {
      callFuncInit(ACCESS_TOKEN ? ACCESS_TOKEN : IS_CHECK);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // walletconnect for mobile
  useEffect(() => {
    if (window.location.pathname.slice(0, 3) === '/en') {
      i18n.changeLanguage('en');
    }

    if (window.location.pathname.slice(0, 3) === '/zh') {
      i18n.changeLanguage('zh');
    }
    fetchPriceRate();
    //comment: close init metamask
    // const initFunc = async () => {
    //   const token = Storage.get('ACCESS_TOKEN');

    //   if (!token) return;

    //   const web3Info = await getWeb3Instance();
    //   let web3;
    //   let accounts;
    //   if (web3Info) {
    //     web3 = web3Info?.web3;
    //     if (!Misc.isMobile) {
    //       accounts = await web3?.eth?.getAccounts();
    //       if (accounts?.length > 0) {
    //         await web3?.currentProvider?.enable();
    //       }
    //       web3.currentProvider.on('accountsChanged', () => {
    //         handleLogout();
    //       });

    //       web3.currentProvider.on('chainChanged', () => {
    //         handleLogout();
    //       });

    //       web3.currentProvider.on('disconnect', () => {
    //         handleLogout();
    //       });
    //     }
    //     if (token && accounts?.length <= 0) {
    //       logout();
    //     }
    //   }
    //   Request.setAccessToken(token);

    //   try {
    //     const hasAccessToken = localStorage.getItem('ACCESS_TOKEN') !== null;
    //     setIniting(true);
    //     if (web3 && web3.currentProvider) {
    //       await onLoginAction({ web3, web3Provider: web3.currentProvider, publicAddress: accounts[0] });
    //       await fetchPriceRate();
    //       await fetchBalanceForMobile(accounts[0]);
    //     } else if (hasAccessToken) {
    //       authStore.setLoggedIn(true);
    //       await authStore.getInitialData();
    //     } else {
    //       handleLogout();
    //     }
    //   } catch (e) {
    //     // eslint-disable-next-line no-console
    //     console.error(e);
    //   } finally {
    //     setIniting(false);
    //   }
    // };

    // if (!localStorage.getItem('Web3Auth-cachedAdapter')) initFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleLogout = () => {
  //   const tokenChange = Storage.get('ACCESS_TOKEN');
  //   const userIdChange = Storage.get('USER_ID');
  //   if (tokenChange && userIdChange) {
  //     logout();
  //   }
  // };

  return !initing || !isLoading ? (
    children
  ) : (
    <PreloadingStyled>
      <Loading size="full-screen" />
    </PreloadingStyled>
  );
};

Init.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Init;

const PreloadingStyled = styled.div`
  background-color: ${Colors.BOX_BACKGROUND};
`;
