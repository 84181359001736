import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { withRouter, NavLink, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import classnames from 'classnames';
import QS from 'query-string';

import Button from '../components/button';
import Typography from '../components/typography';
import Clickable from '../components/clickable';
import { Images, Colors } from '../theme';
import Media from '../utils/media';
import Storage from '../utils/storage';
import { logout } from '../utils/auth';
import { useWeb3Auth } from '../contexts/web3auth/web3auth';
import UserBox from './user-box';
import ChainBox from './chain-box';
import RegisterModal from './register-modal';

const HeaderContainer = styled.header`
  width: 100%;
  height: 92px;
  /* padding: 10px 22px; */
  padding: 0px 22px;
  z-index: 15;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  transition: all 0.2s;
  background-color: #fff;
  font-weight: bold;
  background: #040405;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  ${Media.lessThan(Media.SIZE.XXXS)} {
    height: 45px;
  }
  ${Media.lessThan(Media.SIZE.LG)} {
    height: 60px;
  }

  .text-right {
    text-align: right;
  }

  &.header-blur-active {
    /* background-color: #fff; */
  }

  .left-box {
    display: flex;
    flex: 1;

    ${Media.lessThan(Media.SIZE.LG)} {
      .menu-button {
        margin-left: 3px;
        margin-right: 15px;

        img {
          width: 17px;
        }
      }
    }

    .logo {
      /* margin-right: 56px; */
      z-index: 2;
      align-items: center;
      display: flex;
      color: #fff;
      max-height: 100%;
      width: auto;
      min-width: 130px;
      ${Media.lessThan(Media.SIZE.XL)} {
        min-width: 100px;
      }
      ${Media.lessThan(Media.SIZE.LG)} {
        margin-right: 5px;
        padding-left: 10px;
        height: 38px;
        margin-top: 10px;
      }

      img {
        padding-right: 25px;
        height: 48px;
        width: 100%;
        object-fit: contain;
        border-right: 1px solid #757575;
        ${Media.lessThan(Media.SIZE.XXXS)} {
          max-width: 100px;
          height: 50px;
        }
        ${Media.lessThan(Media.SIZE.XL)} {
          padding-right: 10px;
        }
        ${Media.lessThan(Media.SIZE.LG)} {
          border-right: none;
          max-width: 150px;
          z-index: 99;
        }
      }
    }

    .menu-list {
      display: flex;
      height: 100%;

      ${Media.lessThan(Media.SIZE.LG)} {
        display: none;
      }

      .menu-item {
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${Colors.TEXT};
        white-space: nowrap;
        padding: 0 10px;
        cursor: pointer;
        &.menu-item-ja {
          ${Media.lessThan(Media.SIZE.XL)} {
            padding-right: 10px;
            word-spacing: -20px;
            padding-left: 10px;
            padding-right: 0;
          }
        }
        .sub-name {
          position: absolute;
          right: 0;
          top: 20px;
          text-transform: uppercase;
          font-weight: 900;
          font-size: 10px;
          border-radius: 2px;
          line-height: 14px;
        }

        .typography {
          white-space: nowrap;
          color: ${Colors.TEXT};
          font-weight: bold;
          font-size: 16px;

          ${Media.lessThan(Media.SIZE.XL)} {
            font-size: 14px;
          }
        }

        &:hover {
          opacity: 0.5;
        }

        &.active {
          color: #3cc8fc;

          p,
          .typography {
            color: #3cc8fc;
          }
        }

        ${Media.lessThan(Media.SIZE.XL)} {
          font-size: 14px;
        }
      }
    }

    .menu-external {
      font-size: 16px;
    }
  }

  .language-box {
    display: flex;
    align-items: center;
    /* margin-right: 10px; */
    /* min-width: 40px; */

    .typography {
      font-size: 16px;
      font-weight: bold;
      color: ${Colors.TEXT};
    }

    .flag-icon {
      width: 17px;
      height: 17px;
      box-shadow: 0px 0px 3px 1px #00000021;
      background-position: center;
      background-size: cover;
      margin-right: 5px;
      border-radius: 10px;
      min-width: 0;
      margin-top: 2px;
    }

    .arrow-icon {
      width: 10px;
      min-width: 0;
      margin-left: 5px;
      filter: brightness(0) invert(1);
    }
  }

  .right-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    flex: 1;
    ${Media.lessThan(Media.SIZE.LG)} {
      .menu-list {
        display: none;
      }

      .language-box {
        display: none;
      }

      .login-button {
        padding: 0;
        min-width: 140px;
      }

      .user-box {
        height: 38px;
        padding-left: 5px;
        padding-right: 2px;

        ._left-box {
          min-width: 100px;

          ._horizotal {
            display: flex;
            align-items: flex-end;
            justify-content: center;

            .typography {
              color: #333333;
            }

            > * {
              &:first-child {
                margin-right: 5px;
                font-size: 13px;
              }

              &:last-child {
                margin-bottom: 3px;
                font-size: 10px;
              }
            }
          }

          .typography,
          .header-public-address {
            font-size: 10px;
            text-align: right;
          }
        }

        ._avatar-box {
          margin-left: 5px;

          ._dot {
            top: 0;
            left: 0;
            width: 10px;
            height: 10px;
            border: 2px solid #b9fdfd;
          }

          .avatar-image {
            /* width: 36px;
            height: 36px; */
            width: 32px;
            height: 32px;
          }
        }
      }
    }

    .menu-button {
      margin-left: 20px;
      width: 20px;
      justify-content: center;
      display: flex;
      align-items: center;

      img {
        filter: brightness(0) invert(1);
      }
    }

    .login-button {
      padding: 0 22px;
      border-radius: 60px;
      background-color: #045afc;
      color: #fff;
    }
    .back-button-guide {
      padding: 0 35px;
      border: 1px solid #3cc8fc;
      background-color: transparent;
      color: #3cc8fc;
    }

    .search-box {
      display: flex;
      justify-content: center;
      border-radius: 30px;
      margin-right: 12px;
      min-width: 350px;
      max-width: 420px;
      border-radius: 100px;
      border: 2px solid ${Colors.BORDER};
      ${Media.lessThan(Media.SIZE.XXL)} {
        width: 100%;
        min-width: 150px;
      }
      ${Media.lessThan(Media.SIZE.XL)} {
        width: 100%;
        min-width: 100px;
      }

      > div {
        .input-wrapper {
          .input {
            padding-right: 0;
          }
        }
      }

      ${Media.lessThan(Media.SIZE.LG)} {
        display: none;

        &.open {
          display: flex;
          position: fixed;
          top: 60px;
          padding: 10px;
          background: #262626;
          border-radius: 0;
          width: 100%;
          left: 0;
          z-index: 14;
          border: none;
          border-top: 1px solid rgba(154, 154, 154, 0.2);
          .input-wrapper {
            border-radius: 100px;
            border: 2px solid #efefef;
          }
        }
      }
    }

    .user-box {
      display: flex;
      height: 50px;
      /* background-color: #ffffff; */
      background-color: transparent;
      align-items: center;
      border-radius: 30px;

      ._left-box {
        min-width: 120px;

        ._horizotal {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;

          .typography {
            color: #045afc;
            font-weight: bold;
          }

          > * {
            &:first-child {
              margin-right: 5px;
              font-size: 20px;

              ${Media.lessThan(Media.SIZE.XL)} {
                font-size: 14px;
                white-space: nowrap; 
                overflow: hidden;
                text-overflow: ellipsis; 
              }
            }

            &:last-child {
              margin-bottom: 3px;
              font-size: 14px;

              ${Media.lessThan(Media.SIZE.XL)} {
                font-size: 12px;
                min-width: fit-content;
                margin-bottom: 0px;
              }
            }
          }
        }
      }

      ._avatar-box {
        margin-left: 12px;
        position: relative;

        ._dot {
          position: absolute;
          top: -1px;
          left: -1px;
          width: 15px;
          height: 15px;
          background-color: ${Colors.PRIMARY};
          border-radius: 8px;
          border: 3px solid #b9fdfd;
        }
      }
    }

    .item-box {
      display: flex;
      align-items: center;
      margin-right: 26px;
      margin-top: 5px;

      img {
        margin-right: 15px;
        width: 30px;
        height: 30px;
      }

      ._vertical {
        ._horizontal {
          display: flex;
          align-items: flex-end;
          margin-top: -5px;

          ._label {
            font-size: 13px;
            color: ${Colors.BLACK};
            margin-left: 5px;
            margin-bottom: 3px;
          }

          ._number {
            color: ${Colors.BLACK};
          }
        }
      }
    }
  }

  .menu-panel {
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    display: flex;
    flex-direction: column;
    ${Media.lessThan(Media.SIZE.LG)} {
      top: 60px;
    }

    .top-menu-box {
      .menu-item {
        display: flex;
        justify-content: space-between;
        border-bottom: solid 1px rgba(255, 255, 255, 0.15);
        height: 56px;
        align-items: center;
        padding: 0 20px;

        .typography {
          color: #ffffff;
        }

        > img {
          width: 8px;
        }

        .language-box {
          margin-right: 0;
          ${Media.lessThan(Media.SIZE.LG)} {
            padding-right: 0;
          }

          .arrow-icon {
            width: 13px;
            margin-left: 10px;
            filter: brightness(0) invert(1);
          }

          .typography {
            font-size: 16px;
            color: ${Colors.TEXT};
          }
        }

        &.language-item {
          display: none;
          ${Media.lessThan(Media.SIZE.LG)} {
            display: flex;
          }
        }
      }
    }

    .bottom-menu-box {
      padding: 20px;
      // background-color: ${Colors.BLACK_2};

      .bottom-menu-item {
        margin-bottom: 8px;
        display: block;
        font-size: 14px;
        color: #ffffff;

        &:hover {
          opacity: 0.9;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &.open {
    background-color: rgba(0, 0, 0, 0.8);
  }

  &.is-light {
    &.open {
      background-color: #ffffff;
    }

    .menu-panel .top-menu-box .menu-item .language-box .arrow-icon {
      -webkit-filter: unset;
      filter: unset;
    }
  }

  &.is-light {
    background-color: #fff;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);

    &.header-blur-active {
      /* background-color: #fff; */
    }

    .menu-panel {
      background-color: #ffffff;
    }

    .menu-panel .top-menu-box .menu-item .typography,
    .menu-panel .bottom-menu-box .bottom-menu-item {
      color: #333333;
    }

    .menu-panel .top-menu-box .menu-item {
      border-bottom: solid 1px rgba(0, 0, 0, 0.15);
    }

    .search-box {
      img {
        filter: unset;
      }

      > div {
        background-color: #eeeeee;
        backdrop-filter: unset;
      }

      input::placeholder {
        color: rgba(51, 51, 51, 0.5);
      }

      &.open {
        ${Media.lessThan(Media.SIZE.LG)} {
          background-color: #ffffff;
        }
      }
    }

    .right-box .menu-list .menu-item .typography,
    .language-box .typography {
      color: #fff !important;
    }

    .right-box .menu-list .menu-item.active .typography {
      color: #2664c1;
    }

    .language-box .arrow-icon {
      filter: unset;
    }

    .right-box .menu-external {
      border-left: 1px solid rgba(51, 51, 51, 0.15);
    }

    .right-box .login-button {
      // background-color: #ffffff;
    }

    .right-box .menu-button img {
      filter: unset;
      opacity: 1;
    }
  }
`;

const FlagItemBox = styled(Clickable)`
  display: flex;
  align-items: center;

  // .typography {
  //   color: #333333;
  // }

  img {
    margin-top: 2px;
    width: 20px;
    margin-right: 5px;
    box-shadow: 0px 0px 3px 1px #00000021;
  }
`;

const MenuFixedBottom = styled.div`
  display: none;

  ${Media.lessThan(Media.SIZE.LG)} {
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 15;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: #262626;
    box-shadow: none;

    .header-fixed-bottom-inner {
      display: flex;
      width: 100%;

      ._item {
        /* width: 25%; */
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 6px 10px;
        cursor: pointer;

        ._icon {
          margin-bottom: 4px;

          img {
            width: 30px;
            height: 30px;
            object-fit: contain;
          }

          .hover {
            display: none;
          }
        }

        ._text {
          font-size: 9px;
          color: #9a9a9a;
        }

        &:hover,
        &.active {
          ._icon {
            .hover {
              display: block;
            }

            .normal {
              display: none;
            }
          }

          ._text {
            color: #3cc8fc;
          }
        }
      }
    }
  }

  &.is-dark {
    background-color: #262626;
  }

  &.is-light {
    background: #262626;
    box-shadow: 0 0 6px rgba(34, 34, 34, 0.2);
  }
`;

@withTranslation('common')
@withRouter
@inject(stores => ({
  authStore: stores.auth,
  categoriesStore: stores.categories,
}))
@observer
class Header extends Component {
  static propTypes = {
    authStore: PropTypes.object,
    location: PropTypes.object,
    categoriesStore: PropTypes.object,
    // t: PropTypes.object,
  };

  state = {
    isOpenMenuPanel: false,
    isOpenUserPanel: false,
    isOpenSearchMobile: false,
    classFixedHeader: '',
    // isOpenLoginModal: false,
    isOpenRegisterModal: false,
  };

  _onCloseAllPanel = () => {
    this.setState({
      isOpenMenuPanel: false,
      isOpenUserPanel: false,
      isOpenSearchMobile: false,
    });
  };

  _onLogout = async e => {
    e?.preventDefault();
    const { web3authLogout } = this.props;

    const isWeb3authLogined = localStorage.getItem('Web3Auth-cachedAdapter');
    if (isWeb3authLogined) await web3authLogout();

    logout();
    localStorage.removeItem('IS_AGE_ENOUGH');

    // this.setState({
    //   isOpenMenuPanel: false,
    //   isOpenUserPanel: false,
    //   isOpenSearchMobile: false,
    // });
    this._onCloseAllPanel();
  };

  _onOpenMyPage = e => {
    const { history } = this.props;
    e.preventDefault();

    history.push('/my-page');
    // this.setState({
    //   isOpenMenuPanel: false,
    //   isOpenUserPanel: false,
    //   isOpenSearchMobile: false,
    // });
    this._onCloseAllPanel();
  };

  _onOpenForm = e => {
    const { history } = this.props;
    e.preventDefault();

    // history.push('/my-page/form-benefit');
    history.push('/settings');
    // this.setState({
    //   isOpenMenuPanel: false,
    //   isOpenUserPanel: false,
    //   isOpenSearchMobile: false,
    // });
    this._onCloseAllPanel();
  };

  // _onLoginButtonClick = () => {
  //   // LoginModal.open();
  //   this.setState({
  //     isOpenLoginModal: true,
  //   });
  // };

  // _onLoginModalClose = () => {
  //   this.setState({
  //     isOpenLoginModal: false,
  //   });
  // };

  _onBackHome = () => {
    const { history } = this.props;
    history.push('/');
    this.setState({
      isOpenMenuPanel: false,
      isOpenUserPanel: false,
      isOpenSearchMobile: false,
      defaultCategoryIdSelect: '',
      defaultSearchValue: '',
    });
  };

  _onToggleMenuPanel = () => {
    this.setState(state => ({
      isOpenMenuPanel: !state.isOpenMenuPanel,
      isOpenSearchMobile: false,
      isOpenUserPanel: false,
    }));
  };

  _onToggleUserPanel = () => {
    this.setState(state => ({
      isOpenUserPanel: !state.isOpenUserPanel,
      isOpenSearchMobile: false,
      isOpenMenuPanel: false,
    }));
  };

  _onToggleSearchMobile = () => {
    this.setState(state => ({
      isOpenMenuPanel: false,
      isOpenUserPanel: false,
      isOpenSearchMobile: !state.isOpenSearchMobile,
    }));
  };

  _onChangeLanguage = language => {
    const { i18n, location, history } = this.props;

    if (language === i18n.language) return;

    i18n.changeLanguage(language, () => {
      let newPath = location.pathname.replace('/en/', '/');
      newPath = newPath.replace('/ja/', '/');
      localStorage.setItem('currentLang', language);
      let { query } = QS.parse(location.search);
      if (query) {
        history.replace(`${newPath}?query=${query}`);
      } else {
        history.replace(newPath);
      }
      let element = document.getElementById('web-body');
      element.removeAttribute('class');
      element.classList.add(`${language}`);
    });
  };

  _onMenuPanelClick = item => {
    const { history } = this.props;

    history.push(item.to);

    this.setState({
      isOpenMenuPanel: false,
      isOpenUserPanel: false,
      isOpenSearchMobile: false,
    });
  };

  // _onSearch = () => {
  //   this.setState({
  //     isOpenMenuPanel: false,
  //     isOpenUserPanel: false,
  //     isOpenSearchMobile: false,
  //   });
  // };

  _renderDropdown = () => {
    return ['en', 'ja'].map(item => ({
      key: item,
      label: (
        <FlagItemBox effect={false} onClick={() => this._onChangeLanguage(item)}>
          <img src={Images[`${item.toUpperCase()}_FLAG`]} alt="" />
          <Typography bold style={{ color: Colors.TEXT }}>
            {item === 'en' ? 'English' : '日本語'}
          </Typography>
        </FlagItemBox>
      ),
    }));
  };

  _renderLanguageBox = () => {
    const { i18n, location } = this.props;
    const isDisabled = location.pathname?.includes('/gacha');

    return (
      <Dropdown placement="bottomLeft" trigger="hover" menu={{ items: this._renderDropdown() }} disabled={isDisabled}>
        <Clickable className="language-box menu-item" style={isDisabled ? { cursor: 'no-drop' } : {}}>
          <div
            className="flag-icon"
            style={{
              backgroundImage: `url(${Images[`${i18n.language.toUpperCase()}_FLAG`]})`,
            }}
          />
          {/* <Typography bold>{i18n.language === 'en' ? 'English' : '日本語'}</Typography> */}
          <img className="arrow-icon" src={Images.WHITE_CHEVRON_DOWN_ICON} alt="icon" />
        </Clickable>
      </Dropdown>
    );
  };

  _renderMenuPanel = menuItems => {
    const { t, authStore } = this.props;
    const { isOpenMenuPanel } = this.state;

    const isLogin = authStore.loggedIn && authStore?.initialData?.publicAddress;

    if (!isOpenMenuPanel) return null;

    const BOTTOM_MENU_ITEMS = [
      {
        name: t('header.news'),
        path: '/news',
      },
      {
        name: t('footer.about'),
        path: '/about-us',
      },
      {
        name: t('footer.faq'),
        path: '/faqs',
      },
      {
        name: t('footer.service_term'),
        path: '/service-terms',
      },
      {
        name: t('footer.privacy'),
        path: '/privacy-agreement',
      },
    ];

    return (
      <div className="menu-panel">
        <div className="top-menu-box">
          {menuItems.map((item, index) => (
            <Clickable key={index} className="menu-item" onClick={() => this._onMenuPanelClick(item)}>
              <Typography>{item.name}</Typography>
              <img src={Images.WHITE_CHEVRON_RIGHT_ICON} alt="" />
            </Clickable>
          ))}

          <Clickable
            onClick={() => this._handleCrossSite(!!authStore?.initialData?.publicAddress || authStore.loggedIn)}
            className="menu-item"
          >
            <Typography>{t('header.casino')}</Typography>
          </Clickable>
          <Clickable
            onClick={() => this._handleCrossBetSite(!!authStore?.initialData?.publicAddress || authStore.loggedIn)}
            className="menu-item"
          >
            <Typography>{t('header.bet')}</Typography>
          </Clickable>
          {/* {menuLinks.map((item, index) => (
            <a key={index} className="menu-item" href={item.link_to} target="_blank" rel="noreferrer">
              <Typography>{item.name}</Typography>
              <img src={Images.WHITE_CHEVRON_RIGHT_ICON} alt="" />
            </a>
          ))} */}
          <div className="menu-item language-item">
            <Typography>{t('header.select_a_language')}</Typography>
            {this._renderLanguageBox()}
          </div>
        </div>
        <div className="bottom-menu-box">
          {BOTTOM_MENU_ITEMS.map((item, index) => (
            <Link to={item.path} key={index} className="bottom-menu-item" onClick={this._onCloseAllPanel}>
              {item.name}
            </Link>
          ))}
          {isLogin && (
            <Clickable className="language-box bottom-menu-item" onClick={this._onLogout}>
              {t('header.logout')}
            </Clickable>
          )}
        </div>
      </div>
    );
  };

  _renderUserPanel = () => {
    const { t, authStore } = this.props;
    const { isOpenUserPanel } = this.state;

    const isLogin = authStore.loggedIn && authStore?.initialData?.publicAddress;

    if (!isOpenUserPanel) return null;
    return (
      <div className="menu-panel">
        <div className="top-menu-box">
          <Clickable className="menu-item" onClick={this._onOpenMyPage}>
            <Typography>{t('header.my_page')}</Typography>
            <img src={Images.WHITE_CHEVRON_RIGHT_ICON} alt="" />
          </Clickable>
          <Clickable className="menu-item" onClick={this._onOpenForm}>
            <Typography>{t('header.edit_profile')}</Typography>
            <img src={Images.WHITE_CHEVRON_RIGHT_ICON} alt="" />
          </Clickable>
        </div>
        <div className="bottom-menu-box">
          <ChainBox onSelected={this._onCloseAllPanel} />
          {isLogin && (
            <Clickable className="language-box bottom-menu-item" onClick={this._onLogout}>
              {t('header.logout')}
            </Clickable>
          )}
        </div>
      </div>
    );
  };

  _handleSubmitCrossSite = async ({ accounts, identifier, refresh = false }) => {
    const { authStore } = this.props;

    const resSignIn = await authStore.loginSSOSignIn({
      auth_token: accounts,
      identifier: identifier,
    });
    if (resSignIn.success) {
      window.open(
        `${process.env.REACT_APP_API_URL_GAME}?access_token=${resSignIn.data.access_token}`,
        '_blank',
      );
    } else {
      if (!refresh) {
        const { data, success } = await authStore.refreshAuthToken({
          auth_token: accounts,
          identifier: identifier,
        });
        if (success) {
          return this._handleSubmitCrossSite({
            accounts: data.access_token,
            identifier,
            refresh: true,
          });
        }
      }
      this._onLogout();
    }
  };

  _handleCrossSite = async isLogin => {
    if (isLogin) {
      const { authStore } = this.props;
      const { success, data } = await authStore.retrieveAccount();
      if (success) {
        this._handleSubmitCrossSite({
          accounts: data.accounts[0].auth_token,
          identifier: data.accounts[0].identifier,
        });
      }
    } else {
      window.open(process.env.REACT_APP_API_URL_GAME, '_blank');
    }
  };

  _handleCrossBetSite = async isLogin => {
    if (isLogin) {
      const { authStore } = this.props;
      const { success, data } = await authStore.retrieveTokenBet();
      if (success) {
        window.open(`${process.env.REACT_APP_API_URL_BET}/?ext_token=${data}`, '_blank');
      }
    } else {
      window.open(process.env.REACT_APP_API_URL_BET, '_blank');
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        this.setState({ classFixedHeader: 'header-blur-active' });
      } else {
        this.setState({ classFixedHeader: '' });
      }
    });
  }

  render() {
    const { authStore, t, i18n, location } = this.props;
    const { isOpenLoginModal, toggleOpenModalLogin } = authStore;
    const { isOpenMenuPanel, classFixedHeader } = this.state;
    const currentPath = location?.pathname;
    const isHome = `/${i18n.language}/` === currentPath || `/${i18n.language}` === currentPath;

    const MENU_ITEMS = [
      {
        name: t('header.nft_items'),
        // to: '/search-category-detail/all/new_product/Currency/RANDOM',
        to: '/search-products',
      },
    ];
    const MENU_ITEMS_MOBILE = [
      {
        name: t('header.nft_items'),
        // to: '/search-category-detail/all/new_product/Currency/RANDOM',
        to: '/search-products',
      },
      {
        name: t('header.staking'),
        to: '/staking',
      },
    ];
    // const MENU_LINKS = [
    //   {
    //     name: t('header.airdrop'),
    //     link_to: process.env.REACT_APP_AIRDROP_URL,
    //     subName: t('hot'),
    //   },
    // ];

    const MENU_EXTERNAL_ITEMS = [
      {
        name: t('header.guide'),
        to: '/guide',
      },
      {
        name: t('header.about'),
        to: '/about-us',
      },
      {
        name: t('header.news'),
        to: '/news',
      },
      {
        name: t('header.staking'),
        to: '/staking',
      },
    ];
    const token = Storage.get('ACCESS_TOKEN');
    const isLogin = authStore?.initialData?.publicAddress && !!token;
    return (
      <div className="header">
        <HeaderContainer className={classnames({ open: isOpenMenuPanel }, classFixedHeader)}>
          <div className="left-box">
            <Clickable className="logo" onClick={this._onBackHome}>
              <img src={Images.LOGO} alt="logo" />
            </Clickable>
            <div className="menu-list">
              {MENU_ITEMS.map((item, index) => (
                <NavLink
                  exact
                  key={index}
                  to={`/${i18n.language}${item.to}`}
                  className={classnames('menu-item', i18n.language === 'ja' && 'menu-item-ja')}
                >
                  <Typography>{item.name}</Typography>
                </NavLink>
              ))}
            </div>
            <div className="menu-list menu-external">
              {MENU_EXTERNAL_ITEMS.map((item, index) => (
                <div key={index}>
                  {item.isDisabled ? (
                    <span className={classnames('menu-item', i18n.language === 'ja' && 'menu-item-ja')}>
                      {item.name}
                    </span>
                  ) : (
                      <NavLink
                      exact
                      key={index}
                      to={`/${i18n.language}${item.to}`}
                      className={classnames('menu-item', i18n.language === 'ja' && 'menu-item-ja')}
                      >
                      {item.name}
                    </NavLink>
                  )}
                </div>
              ))}
              {/* {this._renderLanguageBox()} */}
            </div>
            <div className="menu-list">
              <div
                onClick={() => this._handleCrossSite(!!authStore?.initialData?.publicAddress || authStore.loggedIn)}
                className={classnames('menu-item', i18n.language === 'ja' && 'menu-item-ja')}
              >
                <Typography>{t('header.casino')}</Typography>
              </div>
            </div>
            <div className="menu-list">
              <div
                onClick={() => this._handleCrossBetSite(!!authStore?.initialData?.publicAddress || authStore.loggedIn)}
                className={classnames('menu-item', i18n.language === 'ja' && 'menu-item-ja')}
              >
                <Typography>{t('header.bet')}</Typography>
              </div>
            </div>
            {/* <div className="menu-list">
              {MENU_LINKS.map((item, index) => (
                <a
                  key={index}
                  href={item.link_to}
                  target="_blank"
                  rel="noreferrer"
                  className={classnames('menu-item', i18n.language === 'ja' && 'menu-item-ja')}
                >
                  <Typography>{item.name}</Typography>
                  {item.subName && (
                    <Tag className="sub-name" color="#045AFC">
                      {item.subName}
                    </Tag>
                  )}
                </a>
              ))}
            </div> */}
          </div>
          <div className="right-box">
            {/* {!window.location.pathname?.includes('/search-products') && (
              <div className={classnames({ open: isOpenSearchMobile }, 'search-box')}>
                <SearchInput onHeaderSearch={this._onSearch} />
              </div>
            )} */}

            {isLogin ? (
              <UserBox onLogout={this._onLogout} onOpenForm={this._onOpenForm} onOpenMyPage={this._onOpenMyPage} />
            ) : (
              // <Button className="login-button" onClick={this._onLoginButtonClick}>
              <Button className="login-button" onClick={toggleOpenModalLogin}>
                {t('header.login')}
              </Button>
            )}
            <div className="menu-list">{this._renderLanguageBox()}</div>
          </div>

          {this._renderMenuPanel(MENU_ITEMS_MOBILE)}
          {this._renderUserPanel()}
        </HeaderContainer>
        <MenuFixedBottom>
          <div className="header-fixed-bottom-inner">
            <Clickable className={classnames({ active: isHome }, '_item')} onClick={this._onBackHome}>
              <div className="_icon">
                <img className="normal" src={Images.MENU_HOME_ICON} alt="icon" />
                <img className="hover" src={Images.MENU_HOME_ACTIVE_ICON} alt="icon" />
              </div>
              <div className="_text">{t('header.home')}</div>
            </Clickable>
            {!window.location.pathname?.includes('/search-products') && (
              <Clickable className="_item" onClick={this._onToggleSearchMobile}>
                <div className="_icon">
                  <img className="normal" src={Images.MENU_SEARCH_ICON} alt="icon" />
                  <img className="hover" src={Images.MENU_SEARCH_ACTIVE_ICON} alt="icon" />
                </div>
                <div className="_text">{t('header.search')}</div>
              </Clickable>
            )}
            {isLogin ? (
              // <Clickable className="_item" onClick={this._onOpenMyPage}>
              <Clickable className="_item" onClick={this._onToggleUserPanel}>
                <div className="_icon">
                  <img className="normal" src={Images.MENU_WALLET_ICON} alt="icon" />
                  <img className="hover" src={Images.MENU_WALLET_ACTIVE_ICON} alt="icon" />
                </div>
                <div className="_text">{t('header.wallet')}</div>
              </Clickable>
            ) : (
              // <Clickable className="_item" onClick={this._onLoginButtonClick}>
              <Clickable className="_item" onClick={toggleOpenModalLogin}>
                <div className="_icon">
                  <img className="normal" src={Images.MENU_WALLET_ICON} alt="icon" />
                  <img className="hover" src={Images.MENU_WALLET_ACTIVE_ICON} alt="icon" />
                </div>
                <div className="_text">{t('header.wallet')}</div>
              </Clickable>
            )}
            <Clickable className="_item" onClick={this._onToggleMenuPanel}>
              <div className="_icon">
                <img className="normal" src={Images.MENU_MORE_ICON} alt="icon" />
                <img className="hover" src={Images.MENU_MORE_ACTIVE_ICON} alt="icon" />
              </div>
              <div className="_text">{t('header.more')}</div>
            </Clickable>
          </div>
        </MenuFixedBottom>

        {/* {isOpenLoginModal ? <LoginModal onClose={this._onLoginModalClose} isOpen={isOpenLoginModal} /> : null} */}

        <RegisterModal onClose={toggleOpenModalLogin} isOpen={isOpenLoginModal} />
      </div>
    );
  }
}

// export default Header;
export default withWeb3AuthHook(Header);

function withWeb3AuthHook(Component) {
  return function WrappedComponent(props) {
    const { logout: web3authLogout } = useWeb3Auth();

    return <Component {...props} web3authLogout={web3authLogout} />;
  };
}
