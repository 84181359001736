import { useContext } from 'react';
import queryString from 'query-string';

import { MobXProviderContext } from 'mobx-react';
import { useLocation, useHistory } from 'react-router-dom';

import Storage from '../utils/storage';
import Request from '../utils/request';
import Confirmable from '../components/confirmable';
import { channel, logout } from '../utils/auth';
import { useWeb3Auth } from '../contexts/web3auth/web3auth';
import { logout as logoutLocal } from '../utils/auth';
import Toast from '../components/toast';

export const useSSOLogin = () => {
  const { auth } = useContext(MobXProviderContext);
  const { search, pathname } = useLocation();
  const history = useHistory();
  const { logout: web3AuthLogout } = useWeb3Auth();
  const authToken = queryString.parse(search)?.authToken;
  const userId = Number(queryString.parse(search)?.userId);
  const publicAddress = queryString.parse(search)?.publicAddress;

  const loginSSO = async () => {
    Storage.set('ACCESS_TOKEN', authToken);
    Storage.set('USER_ID', userId);
    Storage.set('PUBLIC_ADDRESS', publicAddress);
    Request.setAccessToken(authToken);

    await auth.setLoggedIn(true);
    try {
      const { data, success } = await auth.getInitialData({ headers: authToken });
      await auth.setInitialData({
        publicAddress: data.publicAddress,
        userId: userId,
      });
      if (success) {
        const sessionData = data?.web3SessionData;
        if (sessionData !== null) {
          const parseSession = JSON.parse(sessionData);
          const idSession = parseSession.idSession;
          Storage.set('openlogin_store', idSession);
          const cachedAdapter = parseSession.cachedAdapter;
          localStorage.setItem('Web3Auth-cachedAdapter', cachedAdapter);
          Storage.set('SSO_ADAPTER', idSession);
        } else {
          await web3AuthLogout();
          await logout();
          await logoutLocal();
          await Confirmable.open({
            content: ('Login session has expired, Please login in again!'),
            hideCancelButton: true,
          });
        }
      }
    } catch (error) {
      Toast.error(error?.message);
    }
    channel.postMessage('LOGIN');
    if (pathname.includes('/en') || pathname.includes('/ja')) {
      const newUrl = pathname.replace(/\/(en|ja)/, '');
      history.replace(newUrl);
    } else {
      history.replace(pathname);
    }
    return authToken;
  };

  return { loginSSO };
};

export default useSSOLogin;
