import { types } from 'mobx-state-tree';
import moment from 'moment';

import { Model } from '../utils/mobx-model-helper';
import {
  getOffers,
  registerOffer,
  createOffer,
  registerOfferFlag,
  resellOfferFlag,
  getOfferInfos,
  confirmOffer,
  cancelOffer,
  approvelOffer,
  updateOffer,
  cancelUpdateOffer,
  createOffer1155,
  getOffers1155,
  confirmOffer1155,
  updateOffer1155,
  offerPriceAvg,
} from '../api/offers';

const TYPES = {
  CREATE_OFFER: 1,
  REGISTER_OFFER: 2,
  GET_OFFERS: 3,
  RESELL_OFFER_FLAG: 4,
  GET_OFFER_INFOS: 5,
  CONFIRM_OFFER: 6,
  CANCEL_OFFER: 7,
  REGISTER_OFFER_FLAG: 8,
  APPROVEL_OFFER: 9,
  // UPDATE_OFFER: 10,
  CANCEL_UPDATE_OFFER: 11,
  CREATE_OFFER_1155: 12,
  GET_OFFERS_1155: 13,
  CONFIRM_OFFER_1155: 14,
  UPDATE_OFFER: 15,
  OFFER_PRICE_AVG: 16,
};

const Offer = types
  .model('Offer')
  .props({
    id: types.identifierNumber,
    userId: types.maybeNull(types.number),
    userAvatar: types.maybeNull(types.string),
    userName: types.maybeNull(types.string),
    userPublicAddress: types.maybeNull(types.string),
    offerPrice: types.maybeNull(types.number),
    offerMessage: types.maybeNull(types.string),
    currency: types.maybeNull(types.string),
    heatCount: types.maybeNull(types.number),
    createdAt: types.maybeNull(types.number),
    status: types.maybeNull(types.string),
    approvePublicAddress: types.maybeNull(types.string),
    value: types.maybeNull(types.number),
    email: types.maybeNull(types.string),
  })
  .views(self => ({
    get isNew() {
      return moment().diff(moment(self.createdAt), 'h') <= 72;
    },
  }))
  .actions(self => ({
    setData(data) {
      Object.keys(data).forEach(key => {
        self[key] = data[key];
      });
    },
  }));

const OffersStore = Model.named('OffersStore')
  .props({
    offers: types.array(Offer),
    offers1155: types.model({
      items: types.array(Offer),
      page: types.number,
      total: types.number,
    }),
  })
  .views(self => ({
    get newCount() {
      return self.offers.filter(offer => offer.isNew).length;
    },

    get countOffer() {
      return self.offers.length;
    },

    get countOffer1155() {
      return self.offers1155.total;
    },
  }))
  .actions(self => ({
    setData(data) {
      Object.keys(data).forEach(key => {
        self[key] = data[key];
      });
    },

    getOffers(payload) {
      return self.request({
        type: TYPES.GET_OFFERS,
        api: getOffers,
        payload: {
          ...payload,
          limit: 12,
          sortBy: 'NEWEST_TO_OLDEST',
        },
        onSuccess: result => {
          self.offers = result;
        },
      });
    },

    registerOffer(payload) {
      return self.request({
        type: TYPES.REGISTER_OFFER,
        api: registerOffer,
        payload,
      });
    },

    createOffer(payload) {
      return self.request({
        type: TYPES.CREATE_OFFER,
        api: createOffer,
        payload,
      });
    },

    registerOfferFlag(payload) {
      return self.request({
        type: TYPES.REGISTER_OFFER_FLAG,
        api: registerOfferFlag,
        payload,
      });
    },

    resellOfferFlag(payload) {
      return self.request({
        type: TYPES.RESELL_OFFER_FLAG,
        api: resellOfferFlag,
        payload,
      });
    },

    getOfferInfos(payload) {
      return self.request({
        type: TYPES.GET_OFFER_INFOS,
        api: getOfferInfos,
        payload,
        disabledErrorMessage: true,
      });
    },

    confirmOffer(payload) {
      return self.request({
        type: TYPES.CONFIRM_OFFER,
        api: confirmOffer,
        payload,
        disabledErrorMessage: true,
      });
    },

    cancelOffer(payload) {
      return self.request({
        type: TYPES.CANCEL_OFFER,
        api: cancelOffer,
        payload,
      });
    },

    approvelOffer(payload) {
      return self.request({
        type: TYPES.APPROVEL_OFFER,
        api: approvelOffer,
        payload,
      });
    },

    updateOffer(payload) {
      return self.request({
        type: TYPES.UPDATE_OFFER,
        api: updateOffer,
        payload,
      });
    },

    cancelUpdateOffer(payload) {
      return self.request({
        type: TYPES.CANCEL_UPDATE_OFFER,
        api: cancelUpdateOffer,
        payload,
      });
    },

    createOffer1155(payload) {
      return self.request({
        type: TYPES.CREATE_OFFER_1155,
        api: createOffer1155,
        payload,
      });
    },

    getOffers1155(payload) {
      return self.request({
        type: TYPES.GET_OFFERS_1155,
        api: getOffers1155,
        payload: {
          ...payload,
          sortBy: 'NEWEST_TO_OLDEST',
        },
        onSuccess: result => {
          self.offers1155 = {
            items: result.result,
            page: result.page,
            total: result.total,
          };
        },
      });
    },

    confirmOffer1155(payload) {
      return self.request({
        type: TYPES.CONFIRM_OFFER_1155,
        api: confirmOffer1155,
        payload,
        disabledErrorMessage: true,
      });
    },

    updateOffer1155(payload) {
      return self.request({
        type: TYPES.UPDATE_OFFER_1155,
        api: updateOffer1155,
        payload,
      });
    },

    offerPriceAvg(payload) {
      return self.request({
        type: TYPES.OFFER_PRICE_AVG,
        api: offerPriceAvg,
        payload,
      });
    },
  }));

export { TYPES };
export default OffersStore.create({
  offers: [],
  offers1155: {
    items: [],
    total: 0,
    page: 0,
  },
});
