import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Form, Input, Spin } from 'antd';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

import Modal from '../components/modal';
import Button from '../components/button';
import { Images } from '../theme';
import LoginModal, { FormEmailStyled } from './login-modal';
import { MobXProviderContext } from 'mobx-react';
import { ModalVerifyCode } from '../components/modal-verify-code';

const ContentStyled = styled.div`
  padding: 40px;

  .label-email {
    color: #fff;
    font-size: 14px;
  }
`;

const ModalVerifyCodeAuthStyled = styled(Modal)`
  .ant-modal-body {
    padding: initial !important;
  }
`;

const HeaderStyled = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  .title-header {
    color: #d0d0d0;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

const SubTitleStyled = styled.div`
  display: flex;
  justify-content: center;
  color: #d0d0d0;
  font-size: 16px;
  margin-top: 10px;
  gap: 4px;
  .register-redirect-link {
    color: #1877f2;
    cursor: pointer;
    text-decoration: underline;
  }
`;

const RegisterModal = ({ isOpen, onClose = () => {} }) => {
  const { t } = useTranslation('auth');
  const [loading, setLoading] = useState(false);
  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const [isShowVerifyCode, setIsShowVerifyCode] = useState(false);
  const [dataVerifyCode, setDataVerifyCode] = useState(null);
  const [counter, setCounter] = useState(0);

  const { auth } = useContext(MobXProviderContext);

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email(t('validation_messages:EMAIL_INVALID'))
        .max(30, t('validation_messages:MAX_LENGTH_30_EMAIL'))
        .required(t('validation_messages:EMAIL_REQUIRED')),
    }),
    enableReinitialize: true,
    onSubmit: ({ email }) => {
      handleSubmitLogin(email);
    },
  });

  const schema = Yup.object().shape({
    code: Yup.string().required(t('validation_messages:CODE_REQUIRED')).min(4).max(4),
  });

  const {
    control: controlVerifyCode,
    formState: { errors: errorVerifyCode, isValid: isValidVerifyCode },
    handleSubmit: handleSubmitVerifyCode,
    trigger: triggerVerifyCode,
    getValues: getValueVerifyCode,
    reset: resetVerifyCode,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { touched, handleSubmit, values, isValid, errors, setFieldValue, setFieldTouched } = formik;

  useEffect(() => {
    if (!isShowVerifyCode) {
      resetVerifyCode();
    }
  }, [isShowVerifyCode, resetVerifyCode]);

  const handleCloseModal = () => {
    formik.resetForm({});
    if (onClose) onClose();
  };

  const handleSubmitLogin = async value => {
    setLoading(true);
    const { success, data } = await auth.verifyEmailLogin({ email: value });
    if (success) {
      const time = data?.expiredAt - (moment.utc().unix()) - 1;
      setCounter(time > 60 ? 60 : time);
      setIsShowVerifyCode(true);
      setDataVerifyCode(value);
      handleCloseModal();

    }
    setLoading(false);
  };

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => {
          if (!loading) handleCloseModal();
        }}
        destroyOnClose
        className="custom-modal"
        padding={0}
        title={null}
        width={500}
      >
        <Spin spinning={loading}>
          <HeaderStyled>
            <div className="title-header">{t('login.login')}</div>
          </HeaderStyled>
          <SubTitleStyled>
            <div onClick={() => setIsOpenLogin(true)} className="register-redirect-link">
              {t('login.click_here')}
            </div>
            <span>{t('login.for_new_registration')}</span>
          </SubTitleStyled>
          <ContentStyled>
            <div className="label-email">{t('login.email')}</div>
            <FormEmailStyled>
              <Form.Item
                validateStatus={errors?.email && touched?.email ? 'error' : ''}
                help={
                  <div dangerouslySetInnerHTML={{ __html: touched?.email && errors?.email ? errors?.email : '' }} />
                }
              >
                <Input
                  className="input-email"
                  prefix={<img src={Images.WHITE_EMAIL_ICON} alt="Images.WHITE_EMAIL_ICON" />}
                  placeholder={t('login.enter_your_email')}
                  name="email"
                  onChange={text => {
                    setFieldValue && setFieldValue('email', text.target.value || '');
                  }}
                  value={values?.email}
                  onBlur={() => setFieldTouched('email', true)}
                  disabled={loading}
                />
              </Form.Item>

              <Button className="btn-login-email" disabled={!isValid || loading} onClick={handleSubmit}>
                {t('login.connect_with_email')}
              </Button>
            </FormEmailStyled>
          </ContentStyled>
        </Spin>
      </Modal>
      <LoginModal onClose={() => setIsOpenLogin(false)} isOpen={isOpenLogin} />
      {isShowVerifyCode &&
        <ModalVerifyCodeAuthStyled
          onCancel={() => {
            setIsShowVerifyCode(false);
            setDataVerifyCode(null);
          }}
          open={isShowVerifyCode}
          width={550}
          className="custom-modal"
          closable={false}
        >
          <ModalVerifyCode
            isShowVerifyCode={isShowVerifyCode}
            dataVerifyCode={dataVerifyCode}
            setIsShowVerifyCode={setIsShowVerifyCode}
            setDataVerifyCode={setDataVerifyCode}
            controlVerifyCode={controlVerifyCode}
            errorVerifyCode={errorVerifyCode}
            isValidVerifyCode={isValidVerifyCode}
            handleSubmitVerifyCode={handleSubmitVerifyCode}
            triggerVerifyCode={triggerVerifyCode}
            getValueVerifyCode={getValueVerifyCode}
            resetVerifyCode={resetVerifyCode}
            setCounter={setCounter}
            counter={counter}
          ></ModalVerifyCode>
        </ModalVerifyCodeAuthStyled>
      }
    </>
  );
};

export default RegisterModal;
