import { MainApi } from './endpoint'

export function getListLending(payload) {
  return MainApi.get('/user/lending', payload)
}

export function getLendingById(payload) {
  return MainApi.get(`/user/lending/${payload.id}`, payload)
}

export function borrowLendingProduct(payload) {
  return MainApi.put(`/user/lending/borrow/${payload.productId}`, payload)
}

export function borrowLendingOwned(payload) {
  return MainApi.get(`/user/lending/borrow/owned`, payload)
}

export function cancelLendingOwned(payload) {
  return MainApi.delete(`/user/lending/cancel-lending/${payload.productId}`, payload)
}

export function lendingOwned(payload) {
  return MainApi.get(`/user/lending/owned`, payload)
}

export function registerLendingProduct(payload) {
  return MainApi.post(`/user/lending/register-lending/${payload.productId}`, payload)
}

export function transactionLendingProduct(payload) {
  return MainApi.put(`/user/lending/transaction-hash/${payload.productId}`, payload)
}

export function withdrawLendingOwned(payload) {
  return MainApi.delete(`/user/lending/withdraw-lending/${payload.productId}`, payload)
}

export function lendingPriceAvg(payload) {
  return MainApi.post(`/user/lending/price-avg`, payload)
}
