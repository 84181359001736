import Web3 from 'web3';
import { getPublicCompressed } from '@toruslabs/eccrypto';
import { Web3AuthNoModal } from '@web3auth/no-modal';
import { OpenloginAdapter } from '@web3auth/openlogin-adapter';
import { MetamaskAdapter } from '@web3auth/metamask-adapter';
import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { ADAPTER_EVENTS, CHAIN_NAMESPACES, WALLET_ADAPTERS, getEvmChainConfig } from '@web3auth/base';
import { MobXProviderContext } from 'mobx-react';

import { getWalletProvider } from './walletProvider';
import Toast from '../../components/toast';
import auth from '../../store/auth';
import { getNetworkSelected } from '../../utils/auth';
import Storage from '../../utils/storage';
import Misc from '../../utils/misc';
import Confirmable from '../../components/confirmable';
import { CHAIN_LIST } from '../../constants/chains';
import { t } from 'i18next';
import useSSOLogin from '../../hooks/ssoLogin';
import { useLocation } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';
import MaskLoading from '../../components/mask-loading';

export const Web3AuthContext = createContext({
  web3Auth: null,
  provider: null,
  isLoading: false,
  user: null,
  expiredToken: null,
  refreshToken: null,
  login: async (adapter, provider, login_hint) => {},
  logout: async () => {},
  getUserInfo: async () => {},
  getAccounts: async () => {},
  getBalance: async () => {},
  getAppPubKey: async () => {},
  getPrivateKey: async () => {},
  signMessage: async () => {},
  ethereum: null,
  setExpiredToken: () => {},
  setRefreshToken: () => {},
});

export function useWeb3Auth() {
  return useContext(Web3AuthContext);
}

const env = process.env.REACT_APP_ENV;
const networkWeb3auth = process.env.REACT_APP_WEB3_AUTH_NETWORKS;
const network = getNetworkSelected(env, Storage.get('chain'));
const currentChainConfig = {
  displayName: network?.displayName,
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: `0x${Number(network?.chainId).toString(16)}`,
  rpcTarget: network?.rpcUrls[0],
  blockExplorer: network?.blockExplorerUrls[0],
  ticker: network?.symbol,
  tickerName: network?.currency,
  decimals: network?.decimals,
};

const clientId = process.env.REACT_APP_WEB3_AUTH_CLIENT_ID;
const sessionTime = process.env.REACT_APP_SESSION_TIME;

export const Web3AuthProvider = ({ children }) => {
  const { loginSSO } = useSSOLogin();
  const { auth: authMobX } = useContext(MobXProviderContext);
  const { search } = useLocation();
  // const history = useHistory();
  const [web3Auth, setWeb3Auth] = useState(null);
  const [provider, setProvider] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [expiredToken, setExpiredToken] = useState(
    Storage.get('EXPIRED_TOKEN') || null
  );
  const [refreshToken, setRefreshToken] = useState(
    Storage.get('REFRESH_TOKEN') || null
  );

  const setWalletProvider = useCallback(web3authProvider => {
    // const walletProvider = getWalletProvider(chain, web3authProvider);
    const walletProvider = getWalletProvider(network?.chainId, web3authProvider);
    setProvider(walletProvider);
  }, []);

  const handleSubmitCrossSite = async ({ accounts, identifier }) => {
    const { data, success } = await authMobX.loginSSOWalletSignIn({
      auth_token: accounts,
      identifier: identifier,
    });
    if (success) {
      Storage.set('ACCESS_TOKEN_WALLET', data.access_token);
      Storage.set('PUBLIC_ADDRESS_WALLET', data.public_address);
      Storage.set('USER_ID_WALLET', data.user_id);
      Storage.set('EXPIRED_TOKEN_WALLET', data.expired_at);
      const result = await authMobX.getBalanceWallet(data?.access_token);
      return result;
    }
  };

  const handleCrossSite = async (authToken) => {
    const { success, data } = await authMobX.retrieveAccount(authToken);
    if (success) {
      const result = await handleSubmitCrossSite({
        accounts: data.accounts[0].auth_token,
        identifier: data.accounts[0].identifier,
      });
      return result;
    }
  };

  const handleLoginCrossSite = async () => {
    if (search?.includes('authToken')) {
      MaskLoading.open({});
      const authToken = await loginSSO();
      if (authToken) {
        await handleCrossSite(authToken);
      }
      MaskLoading.close({});
    }
  };

  useEffect(() => {
    const subscribeAuthEvents = web3authInstance => {
      web3authInstance.on(ADAPTER_EVENTS.CONNECTED, data => {
        setUser(data);

        setWalletProvider(web3authInstance.provider);
      });

      // web3authInstance.on(ADAPTER_EVENTS.CONNECTING, () => {});

      web3authInstance.on(ADAPTER_EVENTS.DISCONNECTED, async () => {
        // eslint-disable-next-line no-console
        console.log('ADAPTER_EVENTS --------------- DISCONNECTED');
        await web3authInstance.logout();
        await auth.logout();
        setUser(null);
      });

      web3authInstance.on(ADAPTER_EVENTS.ERRORED, async error => {
        // eslint-disable-next-line no-console
        console.log('ADAPTER_EVENTS --------------- ERRORED', { error });
        if (error.message?.includes('LIQUALITY_ERROR_FROM_ERROR_PARSER_PACKAGE')) {
          // alert(error.message);
        } else {
          web3authInstance.logout();
        }
        setProvider(null);
      });
    };

    async function init() {
      try {
        if (localStorage.getItem('Web3Auth-cachedAdapter')) setIsLoading(true);

        const web3AuthInstance = new Web3AuthNoModal({
          chainConfig: currentChainConfig,
          // enableLogging: false,
          enableLogging: true,
          clientId: clientId,
          // storageKey: 'local',
          sessionTime: Number(sessionTime), // default: 86400
        });

        subscribeAuthEvents(web3AuthInstance);

        // const adapter = new OpenloginAdapter({
        //   // chainConfig: currentChainConfig,
        //   loginSettings: {
        //     mfaLevel: 'none', // default, optional, mandatory, none
        //   },
        //   adapterSettings: {
        //     network: web3AuthNetwork,
        //     clientId,
        //   },
        //   privateKeyProvider,
        // });
        // const network = getNetworksEnv(env);
        // const isCheckNetWork = network.includes('production') ? 'mainnet' : 'testnet'

        const openloginAdapter = new OpenloginAdapter({
          privateKeyProvider: new EthereumPrivateKeyProvider({
            config: {
              chainConfig: getEvmChainConfig(1),
            },
          }),
          loginSettings: {
            mfaLevel: 'none',
          },
          adapterSettings: {
            network: networkWeb3auth,
            clientId,
          },
        });

        const metamaskAdapter = new MetamaskAdapter({
          clientId,
          chainConfig: currentChainConfig,
        });

        // web3AuthInstance.configureAdapter(adapter);
        web3AuthInstance.configureAdapter(openloginAdapter);
        web3AuthInstance.configureAdapter(metamaskAdapter);

        await web3AuthInstance.init();
        await handleLoginCrossSite();

        setWeb3Auth(web3AuthInstance);
        if (!web3AuthInstance?.provider) setIsLoading(false);
      } catch (error) {
        Toast.error(error?.message || 'Initial web3 auth fail.');
      } finally {
        setIsLoading(false);
      }
    }
    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setWalletProvider]);

  // useEffect(() => {
  //   const fetchBalance = async () => {
  //     const balance = await getBalance();
  //     auth.setBalance(balance);
  //   };
  //   if (provider) fetchBalance();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [provider]);

  const switchChain = async chainId => {
    try {
      const advancedNetwork = getNetworkSelected(env, chainId);
      const advancedChainConfig = {
        displayName: advancedNetwork?.displayName,
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        chainId: `0x${Number(advancedNetwork?.chainId).toString(16)}`,
        rpcTarget: advancedNetwork?.rpcUrls[0],
        blockExplorer: advancedNetwork?.blockExplorerUrls[0],
        ticker: advancedNetwork?.symbol,
        tickerName: advancedNetwork?.currency,
        decimals: advancedNetwork?.decimals,
      };

      await web3Auth
        .addChain(advancedChainConfig)
        .then(async () => {
          await web3Auth.switchChain({ chainId: `0x${chainId.toString(16)}` });
          await auth.setCurrentChain(chainId);
        })
        .catch(async e => {
          throw e;
        });
    } catch (error) {
      throw error;
    }
  };

  const login = async (adapter, loginProvider, login_hint) => {
    MaskLoading.open({});
    try {
      if (!web3Auth) {
        MaskLoading.close();
        return;
      }
      const localProvider = await web3Auth.connectTo(adapter, { loginProvider, login_hint });

      await setWalletProvider(localProvider);

      // const walletProvider = getWalletProvider(chain, localProvider);
      const walletProvider = getWalletProvider(network?.chainId, localProvider);
      MaskLoading.close();
      return {
        publicAddress: await walletProvider.getAccounts(),
        privateKey: adapter === WALLET_ADAPTERS.METAMASK ? undefined : await walletProvider.getPrivateKey(),
      };

    } catch (error) {
      MaskLoading.close();
      if (error?.code === 5111) {
        await logout();
      }

      if (error.error === undefined && error.mesage === undefined) {
        if (Misc.isMobile && !Misc.checkNotMetamaskBrowser()) {
          Confirmable.open({
            content: (
              <>
                {t('validation_messages:SOMETHING_WENT_WRONG')}
                <br />
                {t('common:metamask_switch_network_to', { network: CHAIN_LIST[network?.chainId].displayName })}
              </>
            ),
            hideCancelButton: true,
          });
        }
      } else {
        await logout();
      }
      MaskLoading.close();
      throw error;
    }
  };

  const logout = async () => {
    if (!web3Auth) {
      return;
    }
    try {
      if (web3Auth.connected) await web3Auth.logout();
    } catch (e) {}

    setProvider(null);
  };

  const getUserInfo = async () => {
    try {
      if (!web3Auth) {
        return;
      }

      const user = await web3Auth?.getUserInfo();

      return user;
    } catch (error) {
      return error?.message;
    }
  };

  const getAccounts = useCallback(async () => {
    if (!provider) {
      return null;
    }
    const accounts = await provider.getAccounts();

    return accounts;
  }, [provider]);

  const getChainId = async () => {
    if (!provider) {
      return null;
    }
    const chainId = await provider.getCurrentChainId();

    return chainId;
  };

  const getBalance = async () => {
    if (!provider) {
      return;
    }
    const balance = await provider.getBalance();

    return balance;
  };

  const getAppPubKey = async () => {
    try {
      const app_scoped_privkey = await web3Auth.provider?.request({
        method: 'eth_private_key', // use "private_key" for other non-evm chains
      });

      const app_pub_key = getPublicCompressed(Buffer.from(app_scoped_privkey.padStart(64, '0'), 'hex')).toString('hex');

      return app_pub_key;
    } catch (error) {
      return error;
    }
  };

  const getPrivateKey = useCallback(async () => {
    if (!provider) {
      return null;
    }

    try {
      // const walletPrivateKeyWeb3Auth = await provider?.getWalletPrivateKey();
      const privateKeyWeb3Auth = await provider?.getPrivateKey();

      return privateKeyWeb3Auth;
    } catch (error) {
      return null;
    }
  }, [provider]);

  const signMessage = async message => {
    try {
      const web3 = new Web3(provider);

      // Get user's Ethereum public address
      // const fromAddress = (await web3.eth.getAccounts())[0];
      const fromAddress = (await provider.getAccounts())[0];

      // const sign = await provider.signMessage();

      // Sign the message
      const signedMessage = await web3.eth.personal.sign(
        message,
        fromAddress,
        '', // configure your own password here.
      );

      return signedMessage;
    } catch (error) {
      return error;
    }
  };

  const contextProvider = {
    web3Auth,
    provider,
    user,
    isLoading,
    expiredToken,
    refreshToken,
    setIsLoading,
    login,
    logout,
    getUserInfo,
    getChainId,
    getAccounts,
    getBalance,
    getAppPubKey,
    getPrivateKey,
    signMessage,
    switchChain,
    setExpiredToken,
    setRefreshToken,
  };
  return <Web3AuthContext.Provider value={contextProvider}>{children}</Web3AuthContext.Provider>;
};
