import BigNumber from 'bignumber.js';
import moment from 'moment';
import numeral from 'numeral';
import CURRENCIES from '../constants/currencies';

export default class Format {
  static FORMATS = {
    DATE: 'YYYY-MM-DD',
    DATE_TIME: 'YYYY-MM-DD HH:mm',
  };

  static numeric = number => (number || 0).toLocaleString(undefined, { minimumFractionDigits: 0 });

  static date = (date, format) => (date ? moment(date).format(format || 'YYYY-MM-DD') : '');

  static time = (time, format) => (time ? moment(time, 'HH:mm:ss').format(format || 'HH:mm') : '');

  static abridgeNumber = (number = 0) => {
    if (number > 999999999) return `${(number / 1000000000).toFixed(1)}b`;
    if (number > 999999) return `${(number / 1000000).toFixed(1)}m`;
    if (number > 999) return `${(number / 1000).toFixed(1)}k`;
    return number.toString();
  };

  static price = (value, numFixed) => {
    if (value - parseInt(value, 10) > 0) {
      return numeral(value).format(numFixed ? `${numFixed}` : '0,0.0000');
    }

    return numeral(value).format('0,0');
  };

  static priceUp = (value, numFixed) => {
    if (value - parseInt(value, 10) > 0) {
      let roundedValue = Math.ceil(value * 10000) / 10000;
      return numeral(roundedValue).format(numFixed ? `${numFixed}` : '0,0.0000');
    }
    return numeral(value).format('0,0');
  };

  static priceVer2 = (value, numFixed) => {
    if (value - parseInt(value, 10) > 0 && value > '0.0000001') {
      return numeral(value).format(numFixed ? `${numFixed}` : '0,0.0000');
    }

    if (value < '0.000001') {
      return value;
    }

    return numeral(value).format('0,0');
  };

  static handleDecimalPlace = (value, type, comma = ',') => {
    let decimalPlaces;
    switch (type) {
      case CURRENCIES.WMATIC:
        decimalPlaces = 4;
        break;
      case CURRENCIES.JPY:
        decimalPlaces = 0;
        break;
      default:
        decimalPlaces = 2;
        break;
    }

    const factor = new BigNumber(10).pow(decimalPlaces);
    const bigValue = new BigNumber(value);

    // Handle JPY case separately
    if (type === CURRENCIES.JPY) {
      const truncatedNum = bigValue.integerValue(BigNumber.ROUND_FLOOR);
      if (truncatedNum.isNaN()) return '0';

      let result = truncatedNum.toFixed(decimalPlaces);
      let parts = result.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, comma);
      return parts[0];
    } else {
      const truncatedNum = bigValue.multipliedBy(factor).integerValue(BigNumber.ROUND_FLOOR).dividedBy(factor);
      if (truncatedNum.isNaN()) return '0';

      let result = truncatedNum.toFixed(decimalPlaces);
      result = result.replace(/\.?0+$/, '');
      let parts = result.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, comma);
      return parts.join('.');
    }
  };

  static priceDecimalCustom = (value, currency, _format = '0,0.0') => {
    if (currency === 'JPY') {
      return numeral(value).format('0,0');
    }
    const valueNumber = Number(value)
    if (valueNumber - parseInt(valueNumber, 10) > 0 && valueNumber > 0.000001) {
      const roundedNumber = parseFloat(valueNumber.toFixed(1))
      const decimalPart = roundedNumber - Math.floor(roundedNumber);
      if (decimalPart > 0) {
        return numeral(roundedNumber).format(_format)
      } else {
        return numeral(roundedNumber).format('0,0')
      }
      // return numeral(valueNumber).format('0,0.0');
    }

    if (valueNumber < 0.000001) {
      return valueNumber;
    }
    return numeral(valueNumber).format('0,0');
  };
}

export const ArrayCards = ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'JOKER'];

export const formatDataCard = result => {
  const _ = require('lodash');
  const ArrayCardsType = ['SPADES', 'HEART', 'CLUBS', 'DIAMONDS'];

  const dataFormat = _.chain(result)
    .groupBy('cardsValue')
    .map((value, key) => ({
      cardsValue: key,
      listCard: value,
    }))
    .value();

  const resultData = dataFormat.sort((a, b) => ArrayCards.indexOf(a.cardsValue) - ArrayCards.indexOf(b.cardsValue));

  const resultDataFormat = resultData.map(dt => ({
    ...dt,
    listCard: dt.listCard.sort((a, b) => ArrayCardsType.indexOf(a.cardsType) - ArrayCardsType.indexOf(b.cardsType)),
  }));

  return resultDataFormat;
};

export const FormatStatusCard = (statusCard, statusProduct, productOwnerRole) => {
  let result = {
    status: '',
    color: 'white',
  };
  if (statusCard === 'SALE') {
    if (statusProduct === 'SALE' && productOwnerRole === 'ADMIN') {
      result.status = '';
    }
    if (statusProduct === 'NEW' && productOwnerRole === 'ADMIN') {
      result.status = 'COMING SOON';
    }
    if (statusProduct === 'SOLD') {
      result.status = 'SOLD OUT';
      result.color = 'yellow';
    }
    if (statusProduct === 'SALE' && productOwnerRole === 'USER') {
      result.status = 'RESALE';
    }
  } else {
    if (statusProduct === 'SALE' && productOwnerRole === 'USER') {
      result.status = '';
    }
    if (statusProduct === 'SOLD') {
      result.status = 'UNLISTED';
      result.color = 'yellow';
    }
    if (statusProduct === 'SALE' && productOwnerRole === 'ADMIN') {
      result.status = 'IN STORE NOW';
    }
    if (statusProduct === 'NEW' && productOwnerRole === 'ADMIN') {
      result.status = 'COMING SOON';
    }
  }
  return result;
};

export const formatStatusRarityProduct = rarity => {
  let result = {
    status: 'N',
    value: 'NORMAL',
  };

  switch (rarity?.toLowerCase()) {
    case 'ur':
      result = {
        status: 'UR',
        value: 'ULTIMATE RARE',
      };
      break;

    case 'ssr':
      result = {
        status: 'SSR',
        value: 'SUPER SPECIAL RARE',
      };
      break;

    case 'sr':
      result = {
        status: 'SR',
        value: 'SUPER RARE',
      };
      break;
    case 'r':
      result = {
        status: 'R',
        value: 'RARE ',
      };
      break;
    default:
      result = {
        status: 'N',
        value: 'NORMAL',
      };
  }
  return result;
};

export const truncateDecimal = (number, digits = 2) => {
  const factor = Math.pow(10, digits);
  return Math.floor(number * factor) / factor;
}
