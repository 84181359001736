import React from 'react';
import { inject, observer } from 'mobx-react';
// import { useTranslation } from 'react-i18next';
// import styled from 'styled-components';
// import Media from '../utils/media';
// import { Select } from 'antd';
import { useWeb3Auth } from '../contexts/web3auth/web3auth';
import { getNetworkSelected, getNetworks } from '../utils/auth';
import MaskLoading from '../components/mask-loading';
import { WALLET_ADAPTERS } from '@web3auth/base';
import { checkCorrespondingNetwork, getWeb3Instance, openMetamaskBrowser } from '../utils/web3';
import LocalStorage from '../utils/storage';
import Confirmable from '../components/confirmable';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
// import { Colors } from '../theme';
import Misc from '../utils/misc';
import { t } from 'i18next';

const env = process.env.REACT_APP_ENV;
export const chainOptions = getNetworks(env)?.map(item => ({ value: item.chainId, label: item.displayName }));

export const handleSwitchNetwork = async ({
  chainId,
  switchChainWeb3auth,
  authStore,
  getWeb3AuthchainId,
  web3AuthLogout,
  logout,
}) => {
  const web3authAdapter = localStorage.getItem('Web3Auth-cachedAdapter');
  const isLMAdapter = web3authAdapter && web3authAdapter === WALLET_ADAPTERS.METAMASK;

  if (!!isLMAdapter && !Misc.isMobile) {
    await switchChainWeb3auth(chainId);
  } else {
    if (Misc.isMobile && Misc.checkNotMetamaskBrowser()) {
      try {
        MaskLoading.close();
        const ok = await Confirmable.open({
          content: t('common:feature_available_on_metamask'),
        });
        if (ok) {
          openMetamaskBrowser();
        } else {
          const error = { code: 4001 };
          throw error;
        }
      } catch (error) {
        alert(error.message);
      }
    } else {
      const { publicAddress } = authStore?.initialData;
      const defaultChainId = getNetworkSelected(process.env.REACT_APP_ENV, LocalStorage.get('chain'))[0]?.chainId;

      const { web3 } = await getWeb3Instance();
      const selectedAddress = web3.currentProvider.selectedAddress;

      if (publicAddress?.toLowerCase() !== selectedAddress?.toLowerCase()) {
        // eslint-disable-next-line no-throw-literal
        throw { error: 'WALLET_NOT_CORRESPONDING_2' };
      }

      await checkCorrespondingNetwork(chainId || defaultChainId);
    }
  }
};

function ChainBox({ authStore, currentChainId, loggedIn, onSelected }) {
  // const { t } = useTranslation('auth');
  const history = useHistory();
  const {  logout: web3AuthLogout } = useWeb3Auth();
  // const location = useLocation();
  const {
    logout,
    initialData: { publicAddress },
  } = authStore;

  // const [isLoginMetaMaskAdapter, setIsLoginMetaMaskAdapter] = useState(false);

  // const onSwitchNetwork = async chainId => {
  //   try {
  //     if (onSelected) onSelected();
  //     MaskLoading.open({ message: t('login.messages.proccessing_on_metamask') });
  //     await handleSwitchNetwork({
  //       chainId,
  //       switchChainWeb3auth,
  //       authStore,
  //       getWeb3AuthchainId,
  //       web3AuthLogout,
  //       logout,
  //     });
  //   } catch (e) {
  //     MaskLoading.close();
  //     // eslint-disable-next-line no-console
  //     console.log('catch: ', { e });
  //     if (e?.code !== 4001) {
  //       Confirmable.open({
  //         content: t(`validation_messages:${e.error || 'SOMETHING_WENT_WRONG'}`),
  //         hideCancelButton: true,
  //       });
  //     }
  //   } finally {
  //     MaskLoading.close();
  //   }
  // };

  React.useEffect(() => {
    const onMetamaskListener = () => {
      if (window?.ethereum) {
        const allwedNetworks = chainOptions.map(option => option?.value?.toString());
        window.ethereum.on('networkChanged', async function (networkId) {
          // Time to reload your interface with the new networkId
          // eslint-disable-next-line no-console
          console.log('network changed: ', networkId);

          if (!allwedNetworks.includes(networkId)) {
            try {
              await web3AuthLogout();
            } catch (error) {
              // eslint-disable-next-line no-console
              console.log('catch: ', { error });
            } finally {
              await logout();
              history.push('/');
            }
          }
        });
      }
    };

    const removeMetamaskListener = () => {
      if (window?.ethereum) {
        window.ethereum.removeListener('accountsChanged', function (accounts) {
          // cleaner code
        });
        window.ethereum.removeListener('networkChanged', function (networkId) {
          // cleaner code
        });
      }
    };

    if (loggedIn) {
      // const web3authAdapter = localStorage.getItem('Web3Auth-cachedAdapter');
      // const isLMAdapter = web3authAdapter && web3authAdapter === WALLET_ADAPTERS.METAMASK;
      // setIsLoginMetaMaskAdapter(!!isLMAdapter);

      onMetamaskListener();
    }

    return () => removeMetamaskListener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChainId, loggedIn, logout, publicAddress]);
  // }, [currentChainId, isLoginMetaMaskAdapter, loggedIn, logout, publicAddress]);

  if (!loggedIn) return null;
  // const isDisabled = location.pathname?.includes('/gacha');

  return (
    <></>
    // <ChainBoxStyled>
    //   <h2 className="label">{t('common:network')}</h2>
    //   <div className="field">
    //     <Select
    //       dropdownStyle={{ backgroundColor: 'rgb(0 29 73 / 90%)' }}
    //       value={currentChainId}
    //       style={{ width: 240, cursor: isDisabled ? 'no-drop' : 'pointer' }}
    //       // onChange={isLoginMetaMaskAdapter ? handleSelectChainByWeb3auth : handleSelectChainByMetamask}
    //       onChange={onSwitchNetwork}
    //       options={chainOptions}
    //       disabled={isDisabled}
    //     />
    //   </div>
    // </ChainBoxStyled>
  );
}

export default inject(stores => ({
  authStore: stores.auth,
}))(
  observer(({ authStore, ...props }) => {
    return (
      <ChainBox
        {...props}
        authStore={authStore}
        currentChainId={authStore.currentChainId}
        loggedIn={!!authStore?.initialData?.publicAddress || authStore?.loggedIn}
      />
    );
  }),
);

// const ChainBoxStyled = styled.div`
//   color: #fff;
//   z-index: 15;

//   ${Media.lessThan(Media.SIZE.LG)} {
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     margin-bottom: 60px;
//   }

//   .ant-select:not(.ant-select-customize-input) .ant-select-selector {
//     border: 1px solid #292d33;
//     border-radius: 6px;
//   }
//   .ant-select-single.ant-select-open .ant-select-selection-item {
//     color: ${Colors.TEXT};
//     font-weight: 500;
//     box-shadow: 0px 5px 25px rgba(232, 189, 219, 0.3);
//     ${Media.lessThan(Media.SIZE.LG)} {
//       color: #fff;
//     }
//   }
//   .ant-select-arrow {
//     color: #a8aeba;
//   }

//   .label {
//     color: ${Colors.TEXT};
//     font-weight: 500;
//     font-size: 14px;
//     margin-bottom: 2px;
//     margin-top: 10px;
//     ${Media.lessThan(Media.SIZE.LG)} {
//       color: #fff;
//     }
//   }
// `;
