import React, { Component, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Request from '../utils/request';
import Storage from '../utils/storage';
import Loading from '../components/loading';
import Page from '../components/page';
import ScrollToTop from '../utils/scrollToTop';
import Header from './header';
import HeaderGuide from './header-guide';

const Home = lazy(() => import('../pages/home'));
const Categories = lazy(() => import('../pages/categories'));
const Products = lazy(() => import('../pages/products'));
const NftItems = lazy(() => import('../pages/nft-items'));
const ProductDetails = lazy(() => import('../pages/product-details'));
const PreviewProduct = lazy(() => import('../pages/preview-product'));
const UserProfile = lazy(() => import('../pages/user-profile'));
const Settings = lazy(() => import('../pages/settings'));
const NotFound = lazy(() => import('../pages/not-found'));
const ContactForm = lazy(() => import('../pages/user-profile/contact-form'));
const ListViewAllTopNfts = lazy(() => import('../pages/view-all/top-products'));
const ListViewAllFavorites = lazy(() => import('../pages/view-all/favories'));
const CategoryDetail = lazy(() => import('../pages/category-detail'));
const SearchProducts = lazy(() => import('../pages/search-products'));
const SearchWebview = lazy(() => import('../pages/search-webview'));
const CreatorDetail = lazy(() => import('../pages/creator-detail'));
const ServicePage = lazy(() => import('../pages/service-term'));
const PrivacyPage = lazy(() => import('../pages/privacy-agreement'));
// const CreatorsPage = lazy(() => import('../pages/creators'));
const NewsPage = lazy(() => import('../pages/news'));
const NewsDetailPage = lazy(() => import('../pages/news/news-detail'));
const AboutUsPage = lazy(() => import('../pages/about-us'));
const FaqsPage = lazy(() => import('../pages/faqs'));
const ContactPage = lazy(() => import('../pages/contact'));
const GuidePage = lazy(() => import('../pages/guide'));
const StakingPage = lazy(() => import('../pages/staking'));
const GachaPage = lazy(() => import('../pages/gacha'));
const VerifyEmailPage = lazy(() => import('../pages/verify-email'));

const VerticalBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const PrivateRoute = ({ condition, redirect, ...props }) => {
  condition = condition() || Storage.get('ACCESS_TOKEN');

  if (condition) return <Route {...props} />;
  return <Redirect to={redirect} />;
};
PrivateRoute.propTypes = {
  condition: PropTypes.func,
  redirect: PropTypes.string,
};

@withRouter
class Routes extends Component {
  static propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
  };

  _renderLazyComponent = (LazyComponent, params) => props => <LazyComponent {...props} {...params} />;
  componentDidMount() {
    sessionStorage.setItem("isFirstLogin", true);
  }
  render() {
    const currentLang = localStorage.getItem('currentLang');
    const { location } = this.props;
    const currentPath = location?.pathname;
    let pathName = currentPath.slice(4, currentPath.length);
    if (!currentLang) {
      localStorage.setItem('currentLang', 'ja');
    }
    return (
      <VerticalBox>
        {/* {pathName === 'buy-nft' ? <HeaderGuide /> : pathName === 'gacha' ? null : <Header />} */}
        {(pathName.split('/').includes('webview') || pathName.split('/').includes('preview-product')) ? <></>
          : pathName === 'buy-nft' ? <HeaderGuide /> : <Header />}
        <Suspense
          fallback={
            <Page>
              <Loading size="large" />
            </Page>
          }
        >
          <ScrollToTop>
            <Switch>
              <Route exact path="/:lang/" component={this._renderLazyComponent(Home)} />
              <Route exact path="/:lang/categories" component={this._renderLazyComponent(Categories)} />
              <Route
                exact
                path="/:lang/products/:sortBy/:categoryId/:currency/:status"
                component={this._renderLazyComponent(Products)}
              />
              <Route exact path="/:lang/products" component={this._renderLazyComponent(Products)} />
              <Route exact path="/:lang/products/category/:id" component={this._renderLazyComponent(Products)} />

              <Route exact path="/:lang/products/key-word/:query" component={this._renderLazyComponent(Products)} />
              <Route exact path="/:lang/product-details/:id" component={this._renderLazyComponent(ProductDetails)} />
              <Route exact path="/:lang/preview-product/:id" component={this._renderLazyComponent(PreviewProduct)} />
              <Route
                exact
                path="/:lang/product-details/:id/:sellAction"
                component={this._renderLazyComponent(ProductDetails)}
              />
              <Route exact path="/:lang/top-nfts" component={this._renderLazyComponent(ListViewAllTopNfts)} />
              <Route exact path="/:lang/favorites" component={this._renderLazyComponent(ListViewAllFavorites)} />

              <Route
                exact
                path="/:lang/nft-items/:sortBy/:categoryId/:currency/:status"
                component={this._renderLazyComponent(NftItems)}
              />
              <Route exact path="/:lang/nft-items" component={this._renderLazyComponent(NftItems)} />
              <Route exact path="/:lang/nft-items/category/:id" component={this._renderLazyComponent(NftItems)} />
              <Route exact path="/:lang/nft-items/key-word/:query" component={this._renderLazyComponent(NftItems)} />
              <Route exact path="/:lang/news" component={this._renderLazyComponent(NewsPage)} />
              <Route exact path="/:lang/news/:id" component={this._renderLazyComponent(NewsDetailPage)} />
              <Route exact path="/:lang/about-us" component={this._renderLazyComponent(AboutUsPage)} />
              <Route exact path="/:lang/staking" component={this._renderLazyComponent(StakingPage)} />

              <PrivateRoute
                exact
                condition={() => Request.getAccessToken()}
                redirect="/ja"
                path="/:lang/settings"
                component={this._renderLazyComponent(Settings)}
              />
              <PrivateRoute
                exact
                condition={() => Request.getAccessToken()}
                redirect="/ja"
                path="/:lang/my-page"
                component={this._renderLazyComponent(UserProfile)}
              />
              <PrivateRoute
                exact
                condition={() => Request.getAccessToken()}
                redirect="/ja"
                path="/:lang/my-page/:tab"
                component={this._renderLazyComponent(UserProfile)}
              />
              {/*
              <PrivateRoute
                exact
                condition={() => Request.getAccessToken()}
                redirect="/ja"
                path="/:lang/my-page/:tab/product-details/:id"
                component={this._renderLazyComponent(ProductDetails)}
              />

               */}

              <Route exact path="/:lang/category/:id" component={this._renderLazyComponent(CategoryDetail)} />
              {/* <Route
                exact
                path="/:lang/search-category-detail/:id/:tab/:currency/:sort"
                component={this._renderLazyComponent(SearchProducts)}
              /> */}
              <Route exact path="/:lang/search-products/" component={this._renderLazyComponent(SearchProducts)} />
              <Route exact path="/:lang/webview/:type" component={this._renderLazyComponent(SearchWebview)} />
              <Route exact path="/:lang/webview/product-details/:id" component={this._renderLazyComponent(ProductDetails)} />
              <Route exact path="/:lang/creator/:id" component={this._renderLazyComponent(CreatorDetail)} />
              <Route exact path="/:lang/service-terms" component={this._renderLazyComponent(ServicePage)} />
              <Route exact path="/:lang/privacy-agreement" component={this._renderLazyComponent(PrivacyPage)} />
              <Route exact path="/:lang/faqs" component={this._renderLazyComponent(FaqsPage)} />
              <Route exact path="/:lang/contact" component={this._renderLazyComponent(ContactPage)} />
              <Route exact path="/:lang/guide" component={this._renderLazyComponent(GuidePage)} />
              <Route exact path="/:lang/gacha" component={this._renderLazyComponent(GachaPage)} />
              <Route exact path="/:lang/verify-email" component={this._renderLazyComponent(VerifyEmailPage)} />

              {/* <Route exact path="/:lang/guide2" component={this._renderLazyComponent(GuidePage2)} /> */}
              {/* <Route exact path="/:lang/buy-nft" component={this._renderLazyComponent(GuideBuyNftPage)} /> */}
              <Route path="/:lang/not-found" component={this._renderLazyComponent(NotFound)} />

              {/* <Route exact path="/:lang/creators" component={this._renderLazyComponent(CreatorsPage)} /> */}

              <PrivateRoute
                exact
                condition={() => Request.getAccessToken()}
                redirect="/ja"
                path="/:lang/contact-form"
                component={this._renderLazyComponent(ContactForm)}
              />
              <Redirect to="/ja/" />
            </Switch>
          </ScrollToTop>
        </Suspense>
      </VerticalBox>
    );
  }
}

export default Routes;
