import { MainApi } from './endpoint'

export function getProducts(payload) {
  return MainApi.get('/user/product', payload)
}

export function getEventProducts(payload) {
  return MainApi.get('/user/product/event', payload)
}

export function getMyProducts(payload) {
  return MainApi.get('/user/product/owned', payload)
}

export function getMyProductsWithNoPaging(payload) {
  return MainApi.get('/user/product/list-owned', payload)
}

export function getMyBidProducts(payload) {
  return MainApi.get('/user/product/bid', payload)
}

export function getProductDetails({ id, ...payload }) {
  return MainApi.get(`/user/product/${id}`, payload)
}

export function getProductCategories(payload) {
  return MainApi.get('/product/product-attributes', { ...payload, type: 'CATEGORY' })
}

export function getProductFilterData(payload) {
  return MainApi.get('/user/product/filter', payload)
}

export function getPriceRate(payload) {
  return MainApi.get('/user/product/price-rate', payload)
}

export function getPriceChartData({ productId, ...payload }) {
  return MainApi.get(`/user/product/price-chart/${productId}`, payload)
}

export function getTransactionHistories({ productId, ...payload }) {
  return MainApi.get(`/user/product/history-order/${productId}`, payload)
}

export function favorite({ productId }) {
  return MainApi.post(`/user/product/interaction/${productId}`, { actionCode: 'WISH' })
}

export function getMyTransactionHistories({ ...payload }) {
  return MainApi.get('/user/product/transaction-history', payload)
}

export function getProductsWish({ ...payload }) {
  return MainApi.get('/user/product/interaction', payload)
}

export function approveForAllProduct({ productId, ...payload }) {
  return MainApi.post(`/user/product/${productId}/approval-for-all`, payload)
}

export function exchangeRegister({ productId, ...payload }) {
  return MainApi.post(`/user/product/${productId}/exchange-owner/register`, payload)
}

export function exchangeConfirm({ productId, productExchangeOwnerId, ...payload }) {
  return MainApi.post(`/user/product/${productId}/exchange-owner/${productExchangeOwnerId}/confirm`, payload)
}

export function requestParentToken({ productId, ...payload }) {
  return MainApi.post(`/user/product/${productId}/parent-token`, payload)
}

export function getAuthorProductPrice({ productId, ...nickname }) {
  return MainApi.get(`/user/product/price/${productId}`, nickname)
}

export function getProductPrice({ productId }) {
  return MainApi.get(`/user/product/price/${productId}`)
}

export function getCultureOverview(payload) {
  return MainApi.get('/user/product/overview', payload)
}

export function getProductItems(payload) {
  return MainApi.get('/user/product', payload)
}

export function getProductErc1155(payload) {
  return MainApi.get('/user/product/erc1155/owned', payload)
}

export function getProductByCreatorId({ ...payload }) {
  return MainApi.get(`/user/product/${payload.creatorId}/creator`, payload)
}

export function getCurrencyProduct(payload) {
  return MainApi.get('/user/product/mcc-wallet/currency', payload)
}

export function getAvatarPreview({ nftShopId, ...payload }) {
  return MainApi.get(`/user/avatar-preview/${nftShopId}`, payload)
}
