import { types } from 'mobx-state-tree';

import { Model } from '../utils/mobx-model-helper';
import {
  getUserNonce,
  login,
  getInitialData,
  web3authlogin,
  loginSSOSignIn,
  loginSSOWalletSignIn,
  getBalanceWallet,
  retrieveAccount,
  refreshAuthToken,
  getRandomMessage,
  verifyEmail,
  verifyEmailLogin,
  authLogin,
  refreshToken,
  retrieveTokenBet
} from '../api/auth';
import LocalStorage from '../utils/storage';
import { chainOptions } from '../app/chain-box';

const TYPES = {
  GET_USER_NONCE: 1,
  VERIFY_SIGNATURE: 2,
  LOGIN: 3,
  GET_INITIAL_DATA: 4,
  WEB3AUTH_LOGIN: 5,
  GET_RANDOM_MESSAGE: 6,
  RETRIEVE_ACCOUNT: 7,
  LOGIN_SSO_SIGN_IN: 8,
  REFRESH_AUTH_TOKEN: 9,
  VERIFY_EMAIL: 10,
  VERIFY_EMAIL_LOGIN: 11,
  AUTH_LOGIN: 12,
  REFRESH_TOKEN: 13,
  TOKEN_BET_TOKEN: 14,
  LOGIN_SSO_WALLET_SIGN_IN: 8,
  GET_BALANCE_WALLET: 1,
};

const InitialData = types.model('InitialData').props({
  username: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  userId: types.maybeNull(types.number),
  publicAddress: types.maybeNull(types.string),
  nativeBalance: types.maybeNull(types.number),
  userImage: types.maybeNull(types.string),
  ownedCount: types.maybeNull(types.number),
  resaleCount: types.maybeNull(types.number),
  resoldCount: types.maybeNull(types.number),
  sellingCount: types.maybeNull(types.number),
  externalWallet: types.maybeNull(types.boolean),
  balance: types.maybeNull(types.string),
});

const AuthStore = Model.named('AuthStore')
  .props({
    loggedIn: types.boolean,
    currency: types.maybeNull(types.string),
    currentChainId: types.maybeNull(types.number),
    initialData: InitialData,
    isOpenLoginModal: types.boolean,
  })
  .actions(self => ({
    toggleOpenModalLogin() {
      self.isOpenLoginModal = !self.isOpenLoginModal;
    },

    setLoggedIn(value) {
      self.loggedIn = value;
    },

    setCurrency(value) {
      self.currency = value;
    },

    setCurrentChain(value) {
      self.currentChainId = value;
      LocalStorage.set('chain', value);
    },

    setInitialData(data) {
      Object.keys(data).forEach(key => {
        self.initialData[key] = data[key];
      });
    },

    setBalance(value) {
      self.initialData = {
        ...self.initialData,
        nativeBalance: value,
      };
    },

    logout() {
      self.loggedIn = false;
      self.initialData = {
        username: '',
        email: '',
        userId: null,
        publicAddress: null,
        nativeBalance: 0,
        isHideBanner: false,
        externalWallet: false
      };
    },

    getUserNonce(payload) {
      return self.request({
        type: TYPES.GET_USER_NONCE,
        api: getUserNonce,
        payload,
      });
    },

    getRandomMessage(payload) {
      return self.request({
        type: TYPES.GET_RANDOM_MESSAGE,
        api: getRandomMessage,
        payload,
      });
    },

    // verifySignature(payload) {
    //   return self.request({
    //     type: TYPES.VERIFY_SIGNATURE,
    //     api: verifySignature,
    //     payload,
    //   });
    // },

    login(payload) {
      return self.request({
        type: TYPES.LOGIN,
        api: login,
        payload,
      });
    },

    web3authlogin(payload) {
      return self.request({
        type: TYPES.WEB3AUTH_LOGIN,
        api: web3authlogin,
        payload,
      });
    },

    loginSSOSignIn(payload) {
      return self.request({
        type: TYPES.LOGIN_SSO_SIGN_IN,
        api: loginSSOSignIn,
        payload,
      });
    },

    loginSSOWalletSignIn(payload) {
      return self.request({
        type: TYPES.LOGIN_SSO_WALLET_SIGN_IN,
        api: loginSSOWalletSignIn,
        payload,
      });
    },

    getBalanceWallet(payload) {
      return self.request({
        type: TYPES.GET_BALANCE_WALLET,
        api: getBalanceWallet,
        payload,
        onSuccess: result => {
          if (!result) return;
          self.initialData = {
            ...self.initialData,
            balance: result.balance,
          };
        },
      });
    },

    retrieveAccount(payload) {
      return self.request({
        type: TYPES.RETRIEVE_ACCOUNT,
        api: retrieveAccount,
        payload,
      });
    },

    retrieveTokenBet(payload) {
      return self.request({
        type: TYPES.TOKEN_BET_TOKEN,
        api: retrieveTokenBet,
        payload,
      });
    },

    refreshAuthToken(payload) {
      return self.request({
        type: TYPES.REFRESH_AUTH_TOKEN,
        api: refreshAuthToken,
        payload,
      });
    },

    verifyEmail(payload) {
      return self.request({
        type: TYPES.VERIFY_EMAIL,
        api: verifyEmail,
        payload,
      });
    },

    getInitialData(payload) {
      return self.request({
        type: TYPES.GET_INITIAL_DATA,
        api: getInitialData,
        payload,
        onSuccess: result => {
          if (!result) return;
          self.initialData = {
            ...self.initialData,
            ...result,
          };
        },
      });
    },

    verifyEmailLogin(payload) {
      return self.request({
        type: TYPES.VERIFY_EMAIL_LOGIN,
        api: verifyEmailLogin,
        payload,
      });
    },

    authLogin(payload) {
      return self.request({
        type: TYPES.AUTH_LOGIN,
        api: authLogin,
        payload,
      });
    },

    refreshToken(payload) {
      return self.request({
        type: TYPES.REFRESH_TOKEN,
        api: refreshToken,
        payload,
      });
    },
  }));

export { TYPES };
export default AuthStore.create({
  isOpenLoginModal: false,
  loggedIn: false,
  currency: null,
  currentChainId: LocalStorage.get('chain') || chainOptions[0]?.value,
  initialData: {
    nativeBalance: 0,
    // usdToJpy: 0,
    publicAddress: '',
    // ethToJpy: 0,
  },
});
