import { MainApi } from './endpoint';

export function staking(payload) {
  return MainApi.post('/user/staking/stake', payload);
}

export function unStaking(payload) {
  return MainApi.post('/user/staking/un-stake', payload);
}

export function transactionStaking(payload) {
  return MainApi.put('/user/staking/transaction-hash', payload);
}

export function stakingOwned(payload) {
  return MainApi.get(`/user/product/list-staking`, payload);
}

export function getDaysClaim(payload) {
  return MainApi.get(`/user/staking/date-claim/pool/${payload.poolAddress}/chainId/${payload.currentChainId}`);
}

export function signMessageReward(payload) {
  return MainApi.post('/user/staking/sign-message-reward', payload);
}

export function getTokensCanNotStake(payload) {
  return MainApi.get(`/user/staking/tokens-can-not-stake/chain/${payload}`);
}

export function getListNftCategory(payload) { 
  return MainApi.get(`/user/nft-category/list`, payload);
}
