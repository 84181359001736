import { Modal } from 'antd';
import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';

import Media from '../utils/media';
import { CloseOutlined } from '@ant-design/icons';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    /* background-color: #fff !important; */
    background-color: transparent !important;
    border-radius: 16px;
    overflow: hidden;
    width: ${({ width }) => width ?? 648}px;
    max-width: 100%;
    margin: auto;
    box-shadow: 0px 5px 25px rgba(232, 189, 219, 0.3);

    .ant-modal-close {
      right: 20px;
      background: transparent;
      .ant-modal-close-x {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
      }
    }

    .ant-modal-body {
      padding: ${({ padding }) => padding ?? 30}px;
      /* ${Media.lessThan(Media.SIZE.XS)} {
        padding: 0 10px !important;
      } */
    }
  }
  .ant-modal-close-x {
    color: #3d4756;
  }

  &.custom-modal {
    .ant-modal-header {
      /* background-color: #cbd7e8; */
      background-color: #040405;
      border-bottom: none;
      .title {
        color: #fff !important;
        /* font-family: 'Noto Sans JP'; */
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
      }
    }
    .ant-modal-content {
      /* background-color: #fff !important; */
      background-color: #1b1e24 !important;

      .ant-modal-body {
        padding: 0 ${({ padding }) => padding ?? 60}px;
        ${Media.lessThan(Media.SIZE.XS)} {
          /* padding: 0 10px !important; */
          padding: 0 ${({ padding }) => padding / 3 ?? 10}px !important;
        }
        /* padding-bottom: 50px; */
      }
      .title {
        color: #040c14;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #3cc8fc;
  }

  ${Media.lessThan(Media.SIZE.MD)} {
    .ant-modal-content {
      border-radius: 10px;

      .ant-modal-close {
        .ant-modal-close-x {
          width: 50px;
          height: 50px;

          img {
            width: 12px;
          }
        }
      }
    }
  }

  ${Media.lessThan(Media.SIZE.XXXS)} {
    &.modal-bid {
      .ant-modal-content {
        .ant-modal-body {
          padding: 15px !important;
        }
      }
    }
  }
`;

const ModalComponent = ({ children, title, className, width, zIndex, ...props }) => (
  <StyledModal
    centered
    width={width ?? 600}
    footer={null}
    closeIcon={<CloseOutlined style={{ fontSize: '20px' }} />}
    // closeIcon={<span style={{ fontSize: '20px' }}>&times;</span>}
    className={className}
    title={title}
    zIndex={zIndex}
    {...props}
  >
    {children}
  </StyledModal>
);

ModalComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.object,
  className: PropTypes.string,
};

export default ModalComponent;
