import { Model } from '../utils/mobx-model-helper';
import {
  staking,
  transactionStaking,
  unStaking,
  stakingOwned,
  getDaysClaim,
  signMessageReward,
  getTokensCanNotStake,
  getListNftCategory,
} from '../api/staking';
import { types } from 'mobx-state-tree';

const TYPES = {
  STAKING: 1,
  UN_STAKING: 2,
  TRANSACTION_STAKING: 3,
  STAKING_OWNED: 4,
  GET_DAYS_CLAIM: 5,
  SIGN_MESSAGE_REWARD: 6,
  GET_TOKENS_CAN_NOT_STAKE: 7,
};

const Staking = types.model('Staking').props({
  id: types.identifierNumber,
  contractAddress: types.maybeNull(types.string),
  licenseId: types.maybeNull(types.number),
  licenseAddress: types.maybeNull(types.string),
  licenseFee: types.maybeNull(types.number),
  imageUrl: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  price: types.maybeNull(types.number),
  currentTime: types.maybeNull(types.number),
  yenPrice: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  userId: types.maybeNull(types.number),
  size: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  currency: types.maybeNull(types.string),
  holding: types.maybeNull(types.boolean),
  rareFlg: types.maybeNull(types.boolean),
  newAuctionFlag: types.maybeNull(types.boolean),
  productBid: types.maybeNull(
    types.model({
      status: types.maybeNull(types.string),
      startTime: types.maybeNull(types.number),
      endTime: types.maybeNull(types.number),
      biggestBidPrice: types.maybeNull(types.number),
      biggestBidPriceYen: types.maybeNull(types.number),
    }),
  ),
  countView: types.maybeNull(types.number),
  productAttributes: types.maybeNull(
    types.array(
      types.model({
        id: types.maybeNull(types.number),
        name: types.maybeNull(types.string),
        value: types.maybeNull(types.string),
        type: types.maybeNull(types.string),
        attribute: types.maybeNull(types.number),
      }),
    ),
  ),
  series: types.maybeNull(types.string),
  tokenType: types.maybeNull(types.string),
  reselling: types.maybeNull(types.boolean),
  productOwnerRole: types.maybeNull(types.string),
  tokenId: types.maybeNull(types.number),
  offerNftTransactionStatus: types.maybeNull(types.string),
  canOfferFlag: types.maybeNull(types.boolean),
  offerHighestPrice: types.maybeNull(types.number),
  offerHighestPriceCurrency: types.maybeNull(types.string),
  volume: types.maybeNull(types.number),
  categoryName: types.maybeNull(types.string),
  wishCount: types.maybeNull(types.number),
  canResellERC1155: types.maybeNull(types.boolean),
  creatorName: types.maybeNull(types.string),
  isUserWished: types.maybeNull(types.boolean),
  tokenStandard: types.maybeNull(types.string),
  chainId: types.maybeNull(types.number),
  lendingStatus: types.maybeNull(types.string),
  ownerAddress: types.maybeNull(types.string),
  productBuying: types.maybeNull(types.boolean),
  isApproveOffer: types.maybeNull(types.boolean),
  ownerAvatar: types.maybeNull(types.string),
  ownerName: types.maybeNull(types.string),
  pricePerDay: types.maybeNull(types.string),
  rentalDuration: types.maybeNull(types.number),
  rentalDurationEndTime: types.maybeNull(types.number),
  rentalDurationStartTime: types.maybeNull(types.number),
  offerExpirationEndTime: types.maybeNull(types.number),
  offerExpirationStartTime: types.maybeNull(types.number),
  itemColor: types.maybeNull(types.string),
  imageThumbnailUrls: types.array(types.maybeNull(types.string)),
  username: types.maybeNull(types.string),
  lendingTitle: types.maybeNull(types.string),
  offerExpiration: types.maybeNull(types.number),
  stakingStatus: types.maybeNull(types.string),
});

const Stakings = types.model('Stakings').props({
  items: types.array(Staking),
  page: types.maybeNull(types.number),
  total: types.maybeNull(types.number),
});

const NFTSubType = types.model('NFTSubType').props({
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.number)
})

const NFTCategory = types.model('NFTCategory').props({
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  type: types.maybeNull(types.array(NFTSubType))
})

const StakingStore = Model.named('StakingStore')
  .props({
    listStakingOwned: Stakings,
    listTokenNFTCategory: types.array(NFTCategory),
  })
  .actions(self => ({
    postStaking(payload) {
      return self.request({
        type: TYPES.STAKING,
        api: staking,
        payload,
        disabledErrorMessage: true,
      });
    },
    postUnStaking(payload) {
      return self.request({
        type: TYPES.UN_STAKING,
        api: unStaking,
        payload,
        disabledErrorMessage: true,
      });
    },
    transactionStaking(payload) {
      return self.request({
        type: TYPES.TRANSACTION_STAKING,
        api: transactionStaking,
        payload,
        onSuccess: result => {
          // console.log('result', result)
        },
      });
    },
    stakingOwned(payload, { concat }) {
      return self.request({
        type: TYPES.STAKING_OWNED,
        api: stakingOwned,
        payload,
        onSuccess: result => {
          const items = result?.result;
          self.listStakingOwned = {
            items: concat ? self.listStakingOwned.items.concat(items) : items,
            page: result.page,
            total: result.total,
          };
        },
      });
    },
    getDaysClaim(payload) {
      return self.request({
        type: TYPES.GET_DAYS_CLAIM,
        api: getDaysClaim,
        payload,
      });
    },
    postSignMessageReward(payload) {
      return self.request({
        type: TYPES.SIGN_MESSAGE_REWARD,
        api: signMessageReward,
        payload,
        disabledErrorMessage: true,
      });
    },
    getTokensCanNotStake(payload) {
      return self.request({
        type: TYPES.GET_TOKENS_CAN_NOT_STAKE,
        api: getTokensCanNotStake,
        payload,
      });
    },
    getListNftCategory(payload) {
      return self.request({
        type: TYPES.GET_LIST_NFT_CATEGORY,
        api: getListNftCategory,
        payload,
        onSuccess: (result) => {
          self.listTokenNFTCategory = result
        }
      })
    },
  }));

export { TYPES };
export default StakingStore.create({
  listStakingOwned: {
    items: [],
    page: 1,
    total: 0,
    pages: 1,
  },
  listTokenNFTCategory: [],
});
