import tinycolor from 'tinycolor2';

// Color utils
export const darken = (color, value) => tinycolor(color).darken(value).toString();
export const lighten = (color, value) => tinycolor(color).lighten(value).toString();
export const alpha = (color, value) => tinycolor(color).setAlpha(value).toRgbString();
export const isLight = color => tinycolor(color).isLight();

export const PRIMARY = '#3CC8FC';
export const BLACK = '#000000';
export const BLACK_1 = '#313343';
export const BLACK_2 = '#212330';
export const BLACK_3 = '#181A24';
export const BLACK_4 = '#222222';
export const BLACK_5 = '#333333';
export const BLACK_6 = '#414141';
export const BLACK_7 = '#9b9b9b';
export const BLACK_8 = '#616161';
export const GRAY_1 = '#313343';
export const GRAY_2 = '#40414A';
export const GRAY_3 = '#C7C3C4';
export const GRAY_4 = '#908D8E';
export const GRAY_5 = '#c1c1c1';
export const GRAY_6 = '#ADADAD';
export const GRAY_7 = '#f0f0f0';
export const GRAY_8 = '#bfbfbf';
export const GRAY_9 = '#f5f5f5';
export const GRAY_10 = '#F2F2F2';
export const GRAY_11 = '#7F7F7F';
export const GRAY_12 = '#FAFAFA';
export const GRAY_13 = '#E0E0E0';
export const GRAY_14 = '#dcdcdc';
export const GRAY_15 = '#d9dce0';
export const GRAY_16 = '#858585';
export const GRAY_17 = '#b2b2b2';
export const GRAY_18 = '#eeeeee';
export const GRAY_19 = '#909090';
export const GRAY_20 = '#707070';
export const GRAY_21 = '#EDEDED;';
export const WHITE = '#FFFFFF';
export const WHITE_12 = 'rgba(255,255,255,0.12)';
export const WHITE_20 = 'rgba(255,255,255,0.2)';
export const WHITE_35 = 'rgba(255,255,255,0.35)';
export const WHITE_40 = 'rgba(255,255,255,0.40)';
export const WHITE_50 = 'rgba(255,255,255,0.5)';
export const WHITE_70 = 'rgba(255,255,255,0.7)';
export const WHITE_80 = 'rgba(255,255,255,0.8)';
export const BUTTON_BACKGROUND = '#045AFC';
export const BUTTON_TEXT = '#F9FAFB;';
export const BOX_BACKGROUND = '#1B1E24';
export const BORDER = '#A8AEBA';
export const LINE = '#A8AEBA';
export const TEXT = '#A8AEBA';
export const BLUE_TEXT = '#045AFC';
export const PLACEHOLDER = '#65676b';
export const HEADER = '#A8AEBA';
export const BG_HEADER = '#15171C';
export const TITLE = '#E5E7EB';
export const BLUE = '#3CC8FC';
export const PINK = '#FFC1D6';
export const PINK_1 = '#ffc1d6';
export const PINK_2 = '#FFF8FB';
export const PINK_3 = '#FCBDD9';
export const BLUE_1 = '#3CC8FC';
export const BLUE_2 = '#F7FBFF';
export const BLUE_3 = '#CEDEF7';
export const RED = '#e51109';
export const RED_2 = '#e51109';
export const BLUE_4 = '#ffffff';
export const BLUE_5 = '#0A3993';
