import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Popover, Tooltip } from 'antd';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';

import { Colors, Images } from '../theme';
import Thumbnail from '../components/thumbnail';
import Clickable from '../components/clickable';
import BalanceBox from './balance-box';
import ChainBox from './chain-box';
import Typography from '../components/typography';
import Misc from '../utils/misc';
import Storage from '../utils/storage';

function UserBox({ authStore, onLogout, onOpenForm, onOpenMyPage, username, email, publicAddress, userImage }) {
  const { t } = useTranslation(['common']);
  // const [open, setOpen] = useState(false);

  const handleLogout = e => {
    // setOpen(false);
    if (onLogout) onLogout(e);
  };

  const handleOpenForm = e => {
    // setOpen(false);
    if (onOpenForm) onOpenForm(e);
  };

  const handleOpenMyPage = e => {
    // setOpen(false);
    if (onOpenMyPage) onOpenMyPage(e);
  };

  const handleCrossSiteWallet = () => {
    const access_token = Storage.get('ACCESS_TOKEN_WALLET');
    const user_id = Storage.get('USER_ID_WALLET');
    const publicAddress = Storage.get('PUBLIC_ADDRESS_WALLET');
    const expired_at = Storage.get('EXPIRED_TOKEN_WALLET');

    window.open(
      `${process.env.REACT_APP_URL_WALLET}?authToken=${access_token}&userId=${user_id}&publicAddress=${publicAddress}&expiredToken=${expired_at}`,
      '_blank',
    );
  };

  // const handleOpenChange = newOpen => {
  //   setOpen(newOpen);
  // };

  return (
    <Clickable className="user-box">
      <div className="_left-box">
        <div className="_horizotal" onClick={handleCrossSiteWallet}>
          <BalanceBox />
        </div>
        {/* <Typography size="small" className="header-public-address text-right">
            {Misc.trimPublicAddress(authStore.initialData.publicAddress || '', 6)}
          </Typography> */}
      </div>
      <Popover
        placement="bottomRight"
        trigger="hover"
        showArrow={false}
        // open={open}
        // onOpenChange={handleOpenChange}
        // onClick={() => setOpen(prev => !prev)}
        content={
          <>
            {!Misc.isMobile && (
              <StyledPopoverContent>
                <div className="user-box">
                  <Thumbnail
                    rounded
                    size={50}
                    className="avatar-image"
                    url={userImage}
                    placeholderUrl={Images.USER_PLACEHOLDER}
                  />
                  <div className="user-infor">
                    <Tooltip title={username || email || '(username)'} color="black" trigger="hover">
                      <Typography bold className="username">
                        {username || email || '(username)'}
                      </Typography>
                    </Tooltip>
                    <Typography size="small" className="address">
                      {Misc.trimPublicAddress(publicAddress || '', 6)}
                    </Typography>
                  </div>
                </div>
                {/* <ChainBox onSelected={() => setOpen(false)} /> */}
                <ChainBox />
                <Divider className="line" />
                <div className="action-box">
                  <Clickable className="action-item" onClick={handleOpenMyPage}>
                    <Thumbnail url={Images.GRAY_USER_ICON} size={24} className="icon-action" />
                    <span className="action-name">{t('header.my_page')}</span>
                  </Clickable>
                  <Clickable className="action-item" onClick={handleOpenForm}>
                    <Thumbnail url={Images.GRAY_EDIT_ICON} size={22} className="icon-action" />
                    <span className="action-name">{t('header.edit_profile')}</span>
                  </Clickable>
                  <Clickable className="action-item" onClick={handleLogout}>
                    <Thumbnail url={Images.GRAY_LOGOUT_ICON} size={22} className="icon-action" />
                    <span className="action-name">{t('header.logout')}</span>
                  </Clickable>
                </div>
              </StyledPopoverContent>
            )}
          </>
        }
      >
        <div className="_avatar-box">
          {/* <div className="_dot" /> */}
          <Thumbnail
            rounded
            size={50}
            className="avatar-image"
            url={authStore.initialData.userImage}
            placeholderUrl={Images.USER_PLACEHOLDER}
          />
        </div>
      </Popover>
    </Clickable>
  );
}

// export default UserBox;
export default inject(stores => ({
  authStore: stores.auth,
}))(
  observer(({ authStore, ...props }) => {
    const { username = '', email = '', publicAddress = '', userImage = '' } = authStore?.initialData;
    return (
      <UserBox
        {...props}
        authStore={authStore}
        username={username}
        email={email}
        publicAddress={publicAddress}
        userImage={userImage}
      />
    );
  }),
);

const StyledPopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
  padding: 20px;
  .line {
    margin: 10px 0;
    background: #a8aeba;
    opacity: 0.5;
  }
  .user-box {
    display: flex;
    align-items: center;
    gap: 10px;
    .username {
      font-weight: 700;
      font-size: 16px;
      color: ${Colors.TEXT};
      white-space: nowrap;
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .address {
      font-weight: 300;
      font-size: 14px;
      color: ${Colors.TEXT};
    }
  }
  .action-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .action-item {
      display: flex;
      align-items: center;
      gap: 13px;
      .action-name {
        font-weight: 500;
        font-size: 14px;
        color: ${Colors.TEXT};
      }
    }
  }
`;
